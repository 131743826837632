/* eslint-disable react/prop-types */
import React from "react";
import goTop from "@ass/index/gotop.png";
const BackTop = ({ txt, icon = goTop }) => {
  // 回到顶部
  const backTop = () => {
    let timer = setInterval(() => {
      //获取滑动的距离
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      window.scrollTo(0, top - top / 5); //设置返回时 是缓慢带有动画的
      if (top === 0) {
        clearInterval(timer);
      }
    }, 10);
  };

  return (
    <div
      onClick={backTop}
      className="uac ujc udr"
      style={{
        width: 100,
        height: 57,
        borderRadius: 20,
        background: "#FFF",
        marginTop: 16,
      }}
    >
      <img
        src={icon}
        style={{
          width: 35.7,
          height: 35.7,
        }}
      />
      <span>{txt}</span>
    </div>
  );
};
export default BackTop;
