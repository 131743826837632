import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Modal, Tabs, Button, Checkbox, message } from "antd";
import request from "@/service/request";
import "./login.css";
import { saveUser, saveToken } from "@/utils";

import { Logo } from "@components";
import { useMutation } from "react-query";
const items = [
  {
    key: "3",
    label: "登录",
  },
  {
    key: "2",
    label: "注册",
  }
];
const Login = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [formObj, setFormObj] = useState({
    username: "", // admin
    password: "", // admin123
    phonenumber: "",
    remember: true,
    code: "",
    confirmPassword: ""
  });
  const [tabKey, setTabKey] = useState("3"); // 2：注册。3：账号登录

  const changeHandel = (e) => {
    setFormObj({ ...formObj, [e.target.name]: e.target.value })
    console.log(formObj, '-----------', e)
    // formObj.current = { ...formObj.current, };
    // console.log(e.target.value,formObj.current)
  };
  const closeHandel = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setTabKey(e);
  };

  const login = useMutation(async () => {
    // delete formObj.phonenumber;
    // delete formObj.confirmPassword;
    console.log(formObj)
    const res = await request.post("/login", formObj);
    if (res.code === 200) {
      saveToken(res.token);
      saveUser(res.user);
      props.setUserInfo(res.user);
      closeHandel();
    } else {
      message.error(res.msg);
    }
    return res.data;
  });

  const register = useMutation(async () => {
    console.log(formObj)
    if (!formObj.phonenumber) {
      message.error("请输入手机号");
      return;
    }
    if(formObj.password !== formObj.confirmPassword){
      message.error("两次密码不一致")
      return;
    }
    delete formObj.remember;
    const res = await request.post("/register", formObj);
    if (res.code === 200) {
      message.success("注册成功！", 2, () => {
        setTabKey("3");
        setFormObj({
          username: "", // admin
          password: "", // admin123
          phonenumber: "",
          remember: true,
          code: "",
          confirmPassword: ""
        })
      });
    }
    return res.data;
  });

  const openHandel = () => {
    setOpen(true);
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));

  const [code, setCode] = useState('');
  const [sending, setSending] = useState(false);
  const [sentTime, setSentTime] = useState(null);
  const defaultTime = 60;
  const [remainingTime, setRemainingTime] = useState(defaultTime);  
  const [isCountingDown, setIsCountingDown] = useState(false);  
  
  useEffect(() => {  
    if (isCountingDown && remainingTime > 0) {  
      const timer = setTimeout(() => {  
        setRemainingTime((prevTime) => prevTime - 1);  
      }, 1000);  
  
      return () => {  
        clearTimeout(timer);
      };  
    } 
  }, [isCountingDown, remainingTime]);  

  const handleCodeRequest = useMutation(async (options) => {
    const { phonenumber } = formObj
    const regex = /^\d{11}$/; // 只需要11位数字的手机号  
    if (!regex.test(phonenumber)) {
      message.error('请输入正确的手机号');
      return 
    }
    if (sending) return; // 如果正在发送验证码则不重复发送

    setSending(true);
    try {
      // 发送请求获取验证码
      // const encodedMobile = encodeURIComponent(formObj.current.phonenumber);
      // const response = await request.get(`/getCode/${encodedMobile}`);
      setRemainingTime(defaultTime); 
      setIsCountingDown(true)
      const response = await request.get('/getCode', {
        mobile: formObj.phonenumber, // 假设mobile是从表单对象中获取的手机号
        flag: options.flag,
        template: options.template
      });

      if (response.code === 200) {
        setSentTime(Date.now()); // 成功发送后记录当前时间
      } else {
        message.error(response.message || '获取验证码失败');
      }
    } finally {
      setSending(false);
    }
  });
  
  const updatePasswrod = async () => {
    const { code, phonenumber, password } = formObj
    const response = await request.post('/forGetPassword', {
      code, phonenumber, password
    });
    if(response.code === 200){
      closeHandel();
    }
    console.log(response)
  }
  const onTabs = (e) => {
    setFormObj({
      username: "", // admin
      password: "", // admin123
      phonenumber: "",
      remember: true,
      code: ""
    })
    setTabKey(e);
  }
  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="login-modal"
      closeIcon={<img src={require("@ass/base/close.png")} className="close" />}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={1100}
    >
      <div className="login-modal-container">
        <div className="login-modal-left">
          <div className="login-logo">
            {/* <Logo color="#fff" size={24} /> */}
          </div>
          <img src={require("@ass/index/login-bg.png")} className="login-bg" />
        </div>
        <div className="login-modal-right">

          <div className="form-container">
            <Tabs activeKey={tabKey} items={items} onChange={onTabs} />
            <form>
              {tabKey === "3" && (
                <>
                  <div className="udr uac form-item">
                    <span className="form-item-label">账号</span>
                    <DropIcon />
                    <input
                      placeholder="请输入账号/手机号"
                      name="username"
                      // defaultValue={formObj.username}
                      onChange={changeHandel}
                    />
                  </div>
                  <div className="udr uac form-item">
                    <span className="form-item-label">密码</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M4.53566 2.19593V3.63593V5.90393V8.65793V11.4659C4.53566 12.3659 4.53266 13.1939 4.52666 13.9499C4.52066 14.7059 4.51766 15.2939 4.51766 15.7139C4.51766 16.0019 4.58666 16.2419 4.72466 16.4339C4.86266 16.6259 5.03666 16.7549 5.24666 16.8209C5.45666 16.8869 5.68766 16.8869 5.93966 16.8209C6.19166 16.7549 6.42566 16.6139 6.64166 16.3979C7.08566 15.9419 7.56566 15.4649 8.08166 14.9669C8.59766 14.4689 9.12866 13.9679 9.67466 13.4639C10.2207 12.9599 10.7727 12.4559 11.3307 11.9519C11.8887 11.4479 12.4257 10.9619 12.9417 10.4939C13.1697 10.2899 13.3407 10.0379 13.4547 9.73793C13.5687 9.43793 13.6197 9.12593 13.6077 8.80193C13.5957 8.47793 13.5267 8.15993 13.4007 7.84793C13.2747 7.53593 13.0857 7.27193 12.8337 7.05593C12.3657 6.64793 11.9007 6.23693 11.4387 5.82293C10.9767 5.40893 10.5027 4.98293 10.0167 4.54493C9.53066 4.10693 9.02066 3.64793 8.48666 3.16793C7.95266 2.68793 7.38566 2.17793 6.78566 1.63793C6.52166 1.39793 6.25466 1.23893 5.98466 1.16093C5.71466 1.08293 5.47166 1.07093 5.25566 1.12493C5.03966 1.17893 4.86566 1.29893 4.73366 1.48493C4.60166 1.67093 4.53566 1.90793 4.53566 2.19593Z"
                        fill="black"
                      />
                    </svg>
                    <input
                      name="password"
                      type="password"

                      placeholder="请输入密码"

                      onChange={changeHandel}
                    />
                  </div>
                  <div style={{ margin: '10px 0px' }} onClick={() => {
                    setTabKey("4");
                    setFormObj({
                      username: "", // admin
                      password: "", // admin123
                      phonenumber: "",
                      remember: true,
                      code: ""
                    })
                  }}>
                    忘记密码
                  </div>

                </>
              )}
              {tabKey === '1' && (
                <>
                  <div className="udr uac form-item">
                    <span className="form-item-label">手机号</span>
                    <DropIcon />
                    <input
                      name="phonenumber"

                      placeholder="请输入手机号"

                      onChange={changeHandel}
                    />
                  </div>

                  <div className="udr uac form-item">
                    <span className="form-item-label">验证码</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M4.53566 2.19593V3.63593V5.90393V8.65793V11.4659C4.53566 12.3659 4.53266 13.1939 4.52666 13.9499C4.52066 14.7059 4.51766 15.2939 4.51766 15.7139C4.51766 16.0019 4.58666 16.2419 4.72466 16.4339C4.86266 16.6259 5.03666 16.7549 5.24666 16.8209C5.45666 16.8869 5.68766 16.8869 5.93966 16.8209C6.19166 16.7549 6.42566 16.6139 6.64166 16.3979C7.08566 15.9419 7.56566 15.4649 8.08166 14.9669C8.59766 14.4689 9.12866 13.9679 9.67466 13.4639C10.2207 12.9599 10.7727 12.4559 11.3307 11.9519C11.8887 11.4479 12.4257 10.9619 12.9417 10.4939C13.1697 10.2899 13.3407 10.0379 13.4547 9.73793C13.5687 9.43793 13.6197 9.12593 13.6077 8.80193C13.5957 8.47793 13.5267 8.15993 13.4007 7.84793C13.2747 7.53593 13.0857 7.27193 12.8337 7.05593C12.3657 6.64793 11.9007 6.23693 11.4387 5.82293C10.9767 5.40893 10.5027 4.98293 10.0167 4.54493C9.53066 4.10693 9.02066 3.64793 8.48666 3.16793C7.95266 2.68793 7.38566 2.17793 6.78566 1.63793C6.52166 1.39793 6.25466 1.23893 5.98466 1.16093C5.71466 1.08293 5.47166 1.07093 5.25566 1.12493C5.03966 1.17893 4.86566 1.29893 4.73366 1.48493C4.60166 1.67093 4.53566 1.90793 4.53566 2.19593Z"
                        fill="black"
                      />
                    </svg>
                    <input
                      name="code"
                      onChange={changeHandel}
                      placeholder="请输入验证码"
                      maxLength={6}
                    />
                    <div className="udr uac ujc code">
                      <span onClick={()=>handleCodeRequest.mutate({template: "2116390"})}>
                        {sending ? '发送中...' : '获取验证码'}
                      </span>
                    </div>
                 
                  </div>
                </>
              )}
              {tabKey ===
               "2" ? (
                <>
                 <div className="udr uac form-item">
                    <span className="form-item-label">用户名</span>
                    <DropIcon />
                    <input
                      name="username"
                      placeholder="请输入用户名"

                      onChange={changeHandel}
                    />
                  </div>
                  <div className="udr uac form-item">
                    <span className="form-item-label">手机号</span>
                    <DropIcon />
                    <input
                      name="phonenumber"
                      placeholder="请输入手机号"

                      onChange={changeHandel}
                    />
                  </div>
                  
                  <div className="udr uac form-item">
                    <span className="form-item-label">验证码</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M4.53566 2.19593V3.63593V5.90393V8.65793V11.4659C4.53566 12.3659 4.53266 13.1939 4.52666 13.9499C4.52066 14.7059 4.51766 15.2939 4.51766 15.7139C4.51766 16.0019 4.58666 16.2419 4.72466 16.4339C4.86266 16.6259 5.03666 16.7549 5.24666 16.8209C5.45666 16.8869 5.68766 16.8869 5.93966 16.8209C6.19166 16.7549 6.42566 16.6139 6.64166 16.3979C7.08566 15.9419 7.56566 15.4649 8.08166 14.9669C8.59766 14.4689 9.12866 13.9679 9.67466 13.4639C10.2207 12.9599 10.7727 12.4559 11.3307 11.9519C11.8887 11.4479 12.4257 10.9619 12.9417 10.4939C13.1697 10.2899 13.3407 10.0379 13.4547 9.73793C13.5687 9.43793 13.6197 9.12593 13.6077 8.80193C13.5957 8.47793 13.5267 8.15993 13.4007 7.84793C13.2747 7.53593 13.0857 7.27193 12.8337 7.05593C12.3657 6.64793 11.9007 6.23693 11.4387 5.82293C10.9767 5.40893 10.5027 4.98293 10.0167 4.54493C9.53066 4.10693 9.02066 3.64793 8.48666 3.16793C7.95266 2.68793 7.38566 2.17793 6.78566 1.63793C6.52166 1.39793 6.25466 1.23893 5.98466 1.16093C5.71466 1.08293 5.47166 1.07093 5.25566 1.12493C5.03966 1.17893 4.86566 1.29893 4.73366 1.48493C4.60166 1.67093 4.53566 1.90793 4.53566 2.19593Z"
                        fill="black"
                      />
                    </svg>
                    <input
                      name="code"
                      onChange={changeHandel}
                      placeholder="请输入验证码"
                      maxLength={6}
                    />
                    <div className="udr uac ujc code">
                      {
                        !isCountingDown && (
                          <span onClick={() =>handleCodeRequest.mutate({flag : true, template: "2116387"})}>
                            {sending ? '发送中...' : '获取验证码'}
                          </span>
                        )
                      }
                      {isCountingDown && remainingTime !== 0 &&(  
                        <span>  
                          {remainingTime}s 
                        </span>  
                      )}  
                       {remainingTime === 0 && (  
                          <span onClick={() =>handleCodeRequest.mutate({flag : true, template: "2116387"})}>
                          {sending ? '发送中...' : '获取验证码'}
                        </span>  
                        )}
                    </div>
                  </div>
                  <div className="udr uac form-item">
                    <span className="form-item-label">密码</span>
                    <DropIcon />
                    <input
                      name="password"
                      placeholder="请输入密码"
                      type="password"
                      onChange={changeHandel}
                    />
                  </div>
                  <div className="udr uac form-item">
                    <span className="form-item-label">确认密码</span>
                    <DropIcon />
                    <input
                      name="confirmPassword"
                      placeholder="确认密码"
                      type="password"
                      onChange={changeHandel}
                    />
                  </div>

                  <Button
                    type="primary"
                    block
                    shape="round"
                    size="large"
                    onClick={register.mutate}
                    loading={register.isLoading}
                  >
                    注册
                  </Button>
                </>
              ) : (
                <>
                  {tabKey !== "4" &&
                    (<Button
                      type="primary"
                      block
                      shape="round"
                      size="large"
                      onClick={login.mutate}
                      loading={login.isLoading}
                    >
                      登陆
                    </Button>)
                  }
                </>
              )}
              {tabKey === '4' && (
                <>
                  <div className="udr uac form-item">
                    <span className="form-item-label">手机号</span>
                    <DropIcon />
                    <input
                      name="phonenumber"
                      autoComplete="off"
                      placeholder="请输入手机号"

                      onChange={changeHandel}
                    />
                  </div>

                  <div className="udr uac form-item">
                    <span className="form-item-label">验证码</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M4.53566 2.19593V3.63593V5.90393V8.65793V11.4659C4.53566 12.3659 4.53266 13.1939 4.52666 13.9499C4.52066 14.7059 4.51766 15.2939 4.51766 15.7139C4.51766 16.0019 4.58666 16.2419 4.72466 16.4339C4.86266 16.6259 5.03666 16.7549 5.24666 16.8209C5.45666 16.8869 5.68766 16.8869 5.93966 16.8209C6.19166 16.7549 6.42566 16.6139 6.64166 16.3979C7.08566 15.9419 7.56566 15.4649 8.08166 14.9669C8.59766 14.4689 9.12866 13.9679 9.67466 13.4639C10.2207 12.9599 10.7727 12.4559 11.3307 11.9519C11.8887 11.4479 12.4257 10.9619 12.9417 10.4939C13.1697 10.2899 13.3407 10.0379 13.4547 9.73793C13.5687 9.43793 13.6197 9.12593 13.6077 8.80193C13.5957 8.47793 13.5267 8.15993 13.4007 7.84793C13.2747 7.53593 13.0857 7.27193 12.8337 7.05593C12.3657 6.64793 11.9007 6.23693 11.4387 5.82293C10.9767 5.40893 10.5027 4.98293 10.0167 4.54493C9.53066 4.10693 9.02066 3.64793 8.48666 3.16793C7.95266 2.68793 7.38566 2.17793 6.78566 1.63793C6.52166 1.39793 6.25466 1.23893 5.98466 1.16093C5.71466 1.08293 5.47166 1.07093 5.25566 1.12493C5.03966 1.17893 4.86566 1.29893 4.73366 1.48493C4.60166 1.67093 4.53566 1.90793 4.53566 2.19593Z"
                        fill="black"
                      />
                    </svg>
                    <input
                      name="code"
                      onChange={changeHandel}
                      placeholder="请输入验证码"
                      maxLength={6}
                    />
                    <div className="udr uac ujc code">
                    {
                        !isCountingDown && (
                          <span onClick={() =>handleCodeRequest.mutate({flag : true, template: "2140992"})}>
                            {sending ? '发送中...' : '获取验证码'}
                          </span>
                        )
                      }
                      {isCountingDown && remainingTime !== 0 &&(  
                        <span>  
                          {remainingTime}s 
                        </span>  
                      )}  
                       {remainingTime === 0 && (  
                          <span onClick={() =>handleCodeRequest.mutate({flag : true, template: "2140992"})}>
                          {sending ? '发送中...' : '获取验证码'}
                        </span>  
                        )}
                    </div>
                  </div>
                  <div className="udr uac form-item">
                    <span className="form-item-label">新密码</span>
                    <DropIcon />
                    <input
                      name="password"
                      placeholder="新密码"
                      type="password"
                      onChange={changeHandel}
                    />
                  </div>
                  <Button
                    type="primary"
                    block
                    shape="round"
                    size="large"
                    onClick={updatePasswrod}
                  >
                    修改密码
                  </Button>
                </>
              )}
            </form>
          </div>
          <div className="udr uac login-flotter">
            <Checkbox
              checked={checked}
              onChange={() => {
                setChecked(!checked);

              }}
            >
              同意
            </Checkbox>
            <a href="/#/user-agreement" target="_bank">《用户协议》</a>
            <a href="/#/privacy-areement" target="_bank">《隐私政策》</a>
            {tabKey === "1" && (
              <span
                className="curp acc-login"
                onClick={() => {
                  setTabKey("3");
                  setFormObj({
                    username: "", // admin
                    password: "", // admin123
                    phonenumber: "",
                    remember: true,
                    code: ""
                  })
                }}
              >
                使用账号密码登陆
              </span>
            )}
            {tabKey === "3" && (
              <span
                className="curp acc-login"
                onClick={() => {
                  setTabKey("1");
                  setFormObj({
                    username: "", // admin
                    password: "", // admin123
                    phonenumber: "",
                    remember: true,
                    code: ""
                  })
                }}
              >
                使用手机号登陆
              </span>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
const DropIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M4.53566 2.19593V3.63593V5.90393V8.65793V11.4659C4.53566 12.3659 4.53266 13.1939 4.52666 13.9499C4.52066 14.7059 4.51766 15.2939 4.51766 15.7139C4.51766 16.0019 4.58666 16.2419 4.72466 16.4339C4.86266 16.6259 5.03666 16.7549 5.24666 16.8209C5.45666 16.8869 5.68766 16.8869 5.93966 16.8209C6.19166 16.7549 6.42566 16.6139 6.64166 16.3979C7.08566 15.9419 7.56566 15.4649 8.08166 14.9669C8.59766 14.4689 9.12866 13.9679 9.67466 13.4639C10.2207 12.9599 10.7727 12.4559 11.3307 11.9519C11.8887 11.4479 12.4257 10.9619 12.9417 10.4939C13.1697 10.2899 13.3407 10.0379 13.4547 9.73793C13.5687 9.43793 13.6197 9.12593 13.6077 8.80193C13.5957 8.47793 13.5267 8.15993 13.4007 7.84793C13.2747 7.53593 13.0857 7.27193 12.8337 7.05593C12.3657 6.64793 11.9007 6.23693 11.4387 5.82293C10.9767 5.40893 10.5027 4.98293 10.0167 4.54493C9.53066 4.10693 9.02066 3.64793 8.48666 3.16793C7.95266 2.68793 7.38566 2.17793 6.78566 1.63793C6.52166 1.39793 6.25466 1.23893 5.98466 1.16093C5.71466 1.08293 5.47166 1.07093 5.25566 1.12493C5.03966 1.17893 4.86566 1.29893 4.73366 1.48493C4.60166 1.67093 4.53566 1.90793 4.53566 2.19593Z"
        fill="black"
      />
    </svg>
  );
};

export default forwardRef(Login);
