import React, { useState, useEffect, useRef } from "react";
import "./teacherSetting.css";
import Navs from "../../mine/components/navs/navs";
import { getLocalUser } from "@/utils";
import EditModal from "./EditModal";
import { useQuery } from "react-query";
import request from "@/service/request";

export default function TeacherSetting() {
  const [userinfo, setUserinfo] = useState({});
  const editModalRef = useRef(null);

  useEffect(() => {
    const userInfo = getLocalUser();
    setUserinfo(userInfo);
  }, []);

  const user = useQuery(
    [`/system/user/${userinfo.userId}`],
    async () => {
      const res = await request.get(`/system/user/${userinfo.userId}`);
      return res.data;
    },
    {
      enabled: Boolean(userinfo.userId),
    }
  );

  return (
    <>
      <div className="teach-page-containe">
        <div className="mi-nav-box">
          <Navs list={["个人信息"]} />
        </div>
        <ul className="infor-list">
          <li>
            <div className="tit">
              <span>用户名:</span>
              <span>{user.data?.userName}</span>
            </div>
          </li>
          <li>
            <div className="tit">
              <span>手机号:</span>
              <span>{user.data?.phonenumber}</span>
            </div>
          </li>
          <li>
            <div className="tit">
              <span>邮箱:</span>
              <span>{user.data?.email}</span>
            </div>
          </li>
          <li>
            <div className="tit">
              <span>个性签名:</span>
              <span>{user.data?.remake || "这位老师很懒，什么也没留下~"}</span>
            </div>
          </li>
        </ul>
        <div className="mine-info-edit-btn">
          <span
            onClick={() => {
              editModalRef.current.open();
            }}
          >
            修改信息
          </span>
        </div>
      </div>
      <EditModal ref={editModalRef} data={user.data} query={user} />
    </>
  );
}
