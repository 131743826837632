import React, { useRef, useState } from "react";
import user from "@ass/base/user.png";
import pic from "@ass/index/1.jpg";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../addCourse/addCourse.module.css";
import bg from "@ass/classPage/classimg.png";
import empty from "@ass/course/empty.png";
import { useMutation, useQuery } from "react-query";
import request from "@/service/request";
import { Empty, message } from "antd";
import { useClassContext } from "@/pages/teacher/myClass/myClass";

const courseManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = useClassContext();
  const [leftList, setLeftList] = useState([]);
  const [selectStudent, setSelectStudent] = useState([]);

  // 查询未加入课程的同学
  const searchClassesStudent = useQuery(
    ["/mmt/classescourse/classesStudent/" + id],
    async () => {
      const res = await request.post("/mmt/classescourse/classesStudent", {
        classesId: id,
        courseId: params.id,
      });
      setLeftList(res.data);
      return res.data;
    },
    {
      cacheTime: 0,
      enabled: Boolean(params.id) && Boolean(id)
    }
  );

  const goPay = () => {
    if (!selectStudent.length) {
      message.error("请先选择学生");
      return;
    }
    sessionStorage.setItem("payStudent", JSON.stringify(selectStudent));
    navigate("/teacher/order");
  };

  const joinStudent = (item, index) => {
    const list = [...leftList];
    list.splice(index, 1);
    setLeftList(list);
    setSelectStudent([...selectStudent, item]);
  };

  const reduxStudent = (item, index) => {
    const list = [...selectStudent];
    list.splice(index, 1);
    setSelectStudent(list);
    setLeftList([...leftList, item]);
  };

  return (
    <div className={"tea-container " + styles.addCoursePage}>
      <div className="udr uac curp goback">
        <div
          className="udr uac curp"
          onClick={() => {
            navigate(-1);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M17.3906 20.4453L10.6887 13.7434L17.3906 7.04141"
              stroke="#666767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>返回班级</span>
        </div>
      </div>
      <div className={"udr uf1 " + styles.addCourseCon}>
        <div className="uf1 udc system-sub">
          <div className={"udr uac ujb student-search " + styles.studentTitle}>
            未加入同学
          </div>
          {leftList?.length ? (
            <ul className="student-ul">
              {leftList.map((item, index) => {
                return (
                  <li className={`udr uac`} key={index}>
                    <img src={user} />
                    <div>{item.userName}</div>
                    <span
                      className="te-btn"
                      onClick={() => {
                        joinStudent(item, index);
                      }}
                    >
                      添加
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={"uf1 udc ujc "}>
              <Empty />
            </div>
          )}
        </div>
        <div
          className="uf1 system-sub udc system-main"
          style={{ overflowY: "hidden" }}
        >
          <div className={"udr uac ujb student-search " + styles.studentTitle}>
            已添加同学
          </div>
          {selectStudent.length ? (
            <ul className="student-ul" style={{ overflowY: "auto" }}>
              {selectStudent.map((item, index) => {
                return (
                  <li className={`udr uac`} key={index}>
                    <img src={user} />
                    <div>{item.userName}</div>
                    <span
                      className="te-btn"
                      onClick={() => {
                        reduxStudent(item, index);
                      }}
                    >
                      取消
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={"uf1 udc ujc "}>
              <Empty />
            </div>
          )}

          <div className={"udr uac uje " + styles.toBuy}>
            <div className={styles.toBuyBtn} onClick={goPay}>
              确认
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default courseManage;
