import React, { useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import wx from "@ass/course/wx.png";
// import zfb from "@ass/course/zfb.png";
import reload from "@ass/course/reload.svg";
import "./payWindow.css";
import QRCode from "qrcode.react";

import { useMutation } from "react-query";
import request from "@/service/request";

const PayWindow = (props) => {
  const timer = useRef(null);
  const aniTimer = useRef(null);
  const { state, openFunction, callback } = props;

  const [payType] = useState([
    {
      txt: "微信",
      value: "WECHATPAY",
      icon: wx,
    },
    // {
    //   txt: "支付宝",
    //   value: "ALIPAY",
    //   icon: zfb,
    // },
  ]);
  const [aniStatus, setAniStatus] = useState(false);
  const [payTypeIndex, setPayTypeIndex] = useState(0);

  const payData = useMutation(async () => {
    setAniStatus(true);
    aniTimer.current = setTimeout(() => {
      setAniStatus(false);
    }, 1000);
    const res = await request.post("/payment/pay", {
      businessId: props.businessId, // 课程id
      businessType: "BUY_COURSE", // 购买课程
      payPlatformType: payType[payTypeIndex].value, // 支付方式
      userIds: props.userIds ? props.userIds : [], // 购买的学生id， 如果是自己购买就不用传
    });
    setAniStatus(false);
    return res.data;
  });

  // 轮询是否支付
  const getIsPay = useMutation(
    async () => {
      const res = await request.get(
        `/payment/query-payment/${payData.data.paymentId}`
      );
      if (res) {
        if (callback) {
          callback?.();
        } else {
          message.success("购买成功");
        }
        clearInterval(timer.current);
        openFunction();
        props.query?.refetch();
      }
      return res.data;
    },
    {
      enabled: payData.isSuccess,
    }
  );

  useEffect(() => {
    if (state) {
      payData.mutate();
      timer.current = setInterval(() => {
        getIsPay.mutate();
      }, 1000);
    } else {
      clearInterval(timer.current);
    }
    return () => {
      clearInterval(timer.current);
      if (aniTimer.current) {
        clearTimeout(aniTimer.current);
      }
    };
  }, [state]);

  return (
    <Modal
      width={656}
      className="Modal-pay"
      centered
      maskClosable={true}
      onCancel={() => {
        openFunction();
      }}
      closeIcon={false}
      footer
      open={state}
    >
      <div className="pay-content">
        <div className="pay-item ">
          {payType.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setPayTypeIndex(index);
                }}
                className={`wx-pay ${
                  index === payTypeIndex ? "pay-active" : ""
                }`}
                key={index}
              >
                <img src={item.icon} alt="" />
                <div>{item.txt}</div>
              </div>
            );
          })}
        </div>
        <div className="pay-infor">
          <div>
            {payType[payTypeIndex].txt}支付￥{props.money}
          </div>
          {/* <img src={ma} alt="" /> */}
          <QRCode
            value={payData.data?.codeUrl} //生成二维码的内容
            size={178} //二维码尺寸
            fgColor="#000000" //二维码颜色
          />
          <div>请使用{payType[payTypeIndex].txt}扫描二维码进行支付</div>
          <div
            className="reload"
            onClick={() => {
              payData.mutate();
            }}
          >
            <img className={aniStatus ? "element" : ""} src={reload} alt="" />
            重新选择我的订单
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PayWindow;
