import React from "react";
import "./faq.css";
const FAQ = () => {
  return (
    <div className="faq-box">
      <div className="faq-content">
        <div className="t1">FAQ:</div>
        <div className="t2">什么是萌萌码OJ?</div>
        <div className="t3">
          萌萌码OJ 旨在提供一套简单易用的程序设计训练系统.
        </div>
        <div className="t3 tm">
          我们会不定期更新各种难度的程序设计题目。对于每道题目，你的任务是用我们支持的程序设计语言(见下表)编写程序，使其能完成题目的需求，并按照要求的输入/输出格式，在限定的运行时间(如1秒)和内存空间(如128MB)内通过我们的测试数据.
        </div>
        <div className="t3">
          我们会根据通过的测试数据数量给你的提交打分。这道题可以帮助你熟悉我们的系统。
        </div>
        <div className="t3">
          我们还会偶尔举行线上比赛，在一场比赛中，你需要在规定的时间内(从数小时到数天不等)编写程序解决比赛里的题目.
        </div>
        <div className="t2">我提交的程序是怎样运行的？</div>
        <div className="t3">
          后台运行的评测机执行程序的编译、运行、评判任务。评测机以及提交的程序都在Linux环境中运行，支持语言的编译以及执行参数如下:
        </div>

        <table className="txt-tab">
          <tr>
            <td>C</td>
            <td>
              gcc Main.c -O2 -o Main -fno-asm -Wall -lm --static -std=c99
              -DONLINE_JUDGE
            </td>
          </tr>
          <tr>
            <td>C++</td>
            <td>
              g++ Main.cc -O2 -o Main -fno-asm -Wall -lm --static -std=c++0x
              -DONLINE_JUDGE
            </td>
          </tr>
          <tr>
            <td>Pascal</td>
            <td>fpc Main.pas -Cs32000000 -Sh -O2 -Co -Ct -Ci</td>
          </tr>
          <tr>
            <td>Pascal</td>
            <td>python3.* Main.py</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default FAQ;
