import React, { useState } from "react";
import "./studentDetail.css";
import "@/pages/mine/myCourse/myCourse.css";
import user from "@ass/base/user.png";

import Navs from "../../mine/components/navs/navs";
import { Progress, Button, Empty } from "antd";
import question_bg from "@ass/mine/question-bg.png";
import question_img from "@ass/course/12.png";
import { useNavigate } from "react-router-dom";

const studentDetail = (props) => {
  const navigate = useNavigate();
  const [navIndex, setNavIndex] = useState(0);
  const navOnChange = (index) => {
    setNavIndex(index);
  };

  return (
    <div className="course-page-containe stu-container">
      <div
        className="udr uac curp goback"
        onClick={() => {
          navigate(-1);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M17.3906 20.4453L10.6887 13.7434L17.3906 7.04141"
            stroke="#666767"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>返回班级</span>
      </div>
      <div className="udr uac student-user">
        <img src={user} />
        <span>张三丰</span>
      </div>
      <div className="stu-navs">
        <Navs
          list={["学习情况", "比赛情况", "做题情况"]}
          onChange={navOnChange}
        />
      </div>
      {navIndex === 0 ? (
        <ul className="mine-my-cour-ul">
          {[1, 2, 31, 11, 1, 1].map((item, index) => {
            return (
              <li className="udr uac" key={index}>
                <div className="uac ujc udr mine-item-bg" style={{ backgroundImage: `url(${question_bg})` }}>
                  <img src={question_img} className="study-img" />
                </div>
                <div className="uf1 main-info">
                  <div className="main-info-title">请输入课程的名称</div>
                  <div className="main-info-subtitle">
                    此处输入对于课程的简单介绍，以及课程带来的学习好处
                  </div>
                  <div className="main-info-detail">
                    <span className="color-warning">￥499.00</span>
                    <span>
                      <span>学习时常</span>
                      <span className="color-green">25h</span>
                    </span>
                  </div>
                </div>
                <div>
                  <Progress
                    size={74}
                    type="circle"
                    percent={50}
                    strokeWidth={10}
                    strokeColor="#71B07B"
                    trailColor="rgba(113,176,123, .2)"
                  />
                  <div className="my-start-practice">进入练习</div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : navIndex === 1 ? (
        <ul className="mine-my-cour-ul">
          {[1, 2, 31, 11, 1, 1].map((item, index) => {
            return (
              <li className="udr uac" key={index}>
                <div
                  className="uac ujc udr mine-item-bg"
                  style={{ backgroundImage: `url(${question_bg})` }}
                >
                  <img src={question_img} />
                </div>
                <div className="uf1 main-info">
                  <div className="main-info-title">请输入课程的名称</div>
                  <div className="main-info-subtitle">
                    此处输入对于课程的简单介绍，以及课程带来的学习好处
                  </div>
                  <div className="main-info-detail">
                    <span className="color-warning">￥499.00</span>
                    <span>
                      <span>人数</span>
                      <span className="color-green">40</span>
                    </span>
                    <span>
                      <span>学习时常</span>
                      <span className="color-green">25h</span>
                    </span>
                    <span>
                      <span>比赛排名 </span>
                      <span className="color-green">10名</span>
                      <svg
                        className={index < 2 ? "progressive" : ""}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                      >
                        <path
                          d="M6.33293 8.56842C6.73331 9.07696 7.50399 9.07696 7.90436 8.56841L13.0994 1.96981C13.6159 1.31383 13.1486 0.351225 12.3137 0.351225H1.9236C1.08872 0.351225 0.621433 1.31383 1.13788 1.96981L6.33293 8.56842Z"
                          fill="#FF1414"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div>
                  <div className={`competition-score score-level-${index}`}>
                    60分
                  </div>
                  <div className="competition-score-text">比赛成绩</div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="mine-my-cour-ul">
          {[1, 2, 31, 11, 1, 1].map((item, index) => {
            return (
              <li className="udr uac" key={index}>
                <div
                  className="uac ujc udr mine-item-bg"
                  style={{ backgroundImage: `url(${question_bg})` }}
                >
                  <img src={question_img} />
                </div>
                <div className="uf1 main-info">
                  <div className="main-info-title">请输入课程的名称</div>
                  <div className="main-info-subtitle">
                    此处输入对于课程的简单介绍，以及课程带来的学习好处
                  </div>
                  <div className="main-info-detail">
                    <span className="color-warning">￥499.00</span>
                    <span>
                      <span>人数</span>
                      <span className="color-green">40</span>
                    </span>
                    <span>
                      <span>学习时常</span>
                      <span className="color-green">25h</span>
                    </span>
                    <span>
                      <span>正确率 </span>
                      <span className="color-green">80%</span>
                    </span>
                  </div>
                </div>
                <div>
                  <Progress
                    size={74}
                    type="circle"
                    percent={50}
                    strokeWidth={10}
                    strokeColor="#71B07B"
                    trailColor="rgba(113,176,123, .2)"
                  />
                  <div className="my-start-practice">进入练习</div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default studentDetail;
