import React, { useState } from "react";
import { Empty } from "@components";
import { Progress } from "antd";
import question_bg from "@ass/mine/question-bg.png";
import question_img from "@ass/course/12.png";
import Navs from "../components/navs/navs";
import "./question.css";
const question = () => {
  const [status, setStatus] = useState(true);

  return (
    <div className="course-page-containe">
      <div className="mi-nav-box">
        <Navs list={["我的题单"]} />
      </div>
      {status ? (
        <ul className="mine-my-cour-ul">
          {[1, 2, 31, 11, 1, 1].map((item, index) => {
            return (
              <li className="udr uac" key={index}>
                <div
                  className="uac ujc udr mine-item-bg"
                  style={{ backgroundImage: `url(${question_bg})` }}
                >
                  <img src={question_img} />
                </div>
                <div className="uf1 main-info">
                  <div className="main-info-title">请输入课程的名称</div>
                  <div className="main-info-subtitle">
                    此处输入对于课程的简单介绍，以及课程带来的学习好处
                  </div>
                  <div className="main-info-detail">
                    <span className="color-warning">￥499.00</span>
                    <span>
                      <span>人数</span>
                      <span className="color-green">40</span>
                    </span>
                    <span>
                      <span>学习时常</span>
                      <span className="color-green">25h</span>
                    </span>
                  </div>
                </div>
                <div>
                  <Progress
                    size={74}
                    type="circle"
                    percent={50}
                    strokeColor="#71B07B"
                    trailColor="rgba(113,176,123, .2)"
                  />
                  <div className="my-start-practice">进入练习</div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default question;
