import React from "react";
import {
  HashRouter as Router,
  useRoutes,
  Outlet,
  useLocation,
} from "react-router-dom";

import { STUDENT, TEACHER, INSTITUTION } from "@/config";

import WithAuthorization from "./withAuthorization.js"; //鉴权

import Index from "../pages/index"; // 首页
import CourseIndex from "../pages/course/index/index.js"; // 精品课堂
import CourseDetail from "../pages/course/detail/detail"; // 课程详情
import CoursePlay from "../pages/course/play/play"; // 课程播放
import Mine from "../pages/mine/mine"; // 我的   一级路由
import MyCourse from "../pages/mine/myCourse/myCourse.js"; // 我的---我的课程
import Collect from "../pages/mine/collect/collect.js"; // 我的--我的收藏
import Competition from "../pages/mine/competition/competition.js"; // 我的--我的比赛
import MyClass from "../pages/mine/myclass/myclass.js"; // 我的--我的比赛
import Question from "../pages/mine/question/question.js"; // 我的--我的题单

import Teacher from "../pages/teacher/teacher.js"; // 老师 根路由
import TeacherClass from "../pages/teacher/myClass/myClass.js"; // 老师--二级路由--我的班级
import TeacherClassManage from "../pages/teacher/courseManage/courseManage"; // 老师--三级路由 --班级管理
import StudentDetail from "../pages/teacher/studentDetail/studentDetail.js"; // 老师--三级路由 --学生详情
import AddCourse from "../pages/teacher/addCourse/addCourse.js"; // 老师--三级路由 --添加课程
import CourseClass from "../pages/teacher/courseClass/courseClass.js"; // 老师--三级路由 --班级添加课程
import TeacherAddStudent from "../pages/teacher/addStudent/addStudent.js"; // 老师--三级路由 --班级课程添加学生
import MatchIndex from "../pages/teacher/match/index.js"; // 老师--二级路由 --
import MatchIndexHome from "../pages/teacher/match/home.js"; // 老师--三级路由 --比赛管理
import TeacherSetting from "../pages/teacher/teacherSetting/teacherSetting"; // 老师--二级级路由 --个人设置
import TeacherOrder from "../pages/teacher/order/order.js"; // 老师购买课程

import MineInfor from "../pages/mine/mineInfoor/mineInfoor.js"; //我的设置

import OJIndex from "../pages/OJ/Index/index"; //Oj首页
import Game from "../pages/OJ/game/game"; //Oj比赛
import State from "../pages/OJ/state/state"; //Oj用户状态
import User from "../pages/OJ/user/user"; //Oj用户
import FAQ from "../pages/OJ/faq/faq"; //Oj FAQ
import QuestionBank from "../pages/OJ/questionBank/questionBank"; //Oj首页
import QuestionDetail from "../pages/OJ/questionBank/detail"; // 题目详情
import AgentIndex from "../pages/agent/index/index"; //代理人首页---一级
import AgentSchool from "../pages/agent/school/school.js"; // 学校管理--二级
import InformationIndex from "../pages/information/index/index"; //相关资料首页

import UserAgreement from "../pages/index/UserAgreement.js";
import PrivacyAgreement from "../pages/index/PrivacyAgreement";
const GetRoutes = () => {
  const routes = useRoutes([
    {
      path: "/",
      index: true,
      element: <Index />,
    },
    {
      path: "/course",
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          index: true,
          element: <CourseIndex />,
        },
        {
          path: "/course/detail/:id",
          element: <CourseDetail />,
        },
        {
          path: "/course/play/:id/:index/:c_index",
          element: <CoursePlay />,
        },
      ],
    },
    {
      path: "/mine",
      element: <WithAuthorization Component={Mine} allowedRoles={[STUDENT]} />, // <Mine />
      children: [
        {
          index: true,
          path: "/mine/index",
          element: <MyCourse />,
        },
        {
          index: true,
          path: "/mine/collect",
          element: <Collect />,
        },
        {
          index: true,
          path: "/mine/mineInfor",
          element: <MineInfor />,
        },
        {
          path: "/mine/competition",
          element: <Competition />,
        },
        {
          path: "/mine/myclass",
          element: <MyClass />,
        },
        {
          path: "/mine/question",
          element: <Question />,
        },
      ],
    },
    {
      path: "/teacher",
      element: (
        <WithAuthorization Component={Teacher} allowedRoles={[TEACHER]} />
      ),
      children: [
        {
          path: "/teacher/index",
          element: <TeacherClass />,
          children: [
            {
              index: true,
              path: "/teacher/index/courseManage",
              element: <TeacherClassManage />,
            },
            {
              path: "/teacher/index/studentDetail",
              element: <StudentDetail />,
            },
            {
              path: "/teacher/index/addCourse",
              element: <AddCourse />,
            },
            {
              path: "/teacher/index/addStudent/:id",
              element: <TeacherAddStudent />,
            },
            {
              path: "/teacher/index/courseClass",
              element: <CourseClass />,
            },
          ],
        },
        {
          path: "/teacher/match",
          element: <MatchIndex />,
          children: [
            {
              index: true,
              path: "/teacher/match/index",
              element: <MatchIndexHome />,
            },
          ],
        },
        {
          path: "/teacher/teacherSetting",
          element: <TeacherSetting />,
        },
      ],
    },
    {
      path: "/agentIndex",
      element: (
        <WithAuthorization
          Component={AgentIndex}
          allowedRoles={[INSTITUTION]}
        />
      ),
      children: [
        {
          index: true,
          path: "/agentIndex/school",
          element: <AgentSchool />,
        },
        {
          path: "/agentIndex/teacherSetting",
          element: <TeacherSetting />,
        },
      ],
    },
    {
      path: "/teacher/order",
      element: (
        <WithAuthorization Component={TeacherOrder} allowedRoles={[TEACHER]} />
      ),
    },
    {
      path: "/oj",
      element: <OJIndex />,
      children: [
        {
          path: "/oj/questionBank",
          index: true,
          element: <QuestionBank />,
        },

        {
          path: "/oj/game",
          element: <Game />,
        },
        {
          path: "/oj/state",
          element: <State />,
        },
        {
          path: "/oj/user",
          element: <User />,
        },
        {
          path: "/oj/faq",
          element: <FAQ />,
        },
      ],
    },
    {
      path: "/questionDetail",
      element: <QuestionDetail />,
    },

    {
      path: "/informationIndex",
      element: <InformationIndex />,
      children: [],
    },
    {
      path: "/user-agreement",
      element: <UserAgreement />,
    },
    {
      path: "/privacy-areement",
      element: <PrivacyAgreement />,
    },
  ]);
  return routes;
};

const SetRoutes = () => {
  return (
    <Router>
      <GetRoutes />
      <div className={`information`}><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023028048号</a></div>
    </Router>
  );
};

export default SetRoutes;
