import React from "react";
import Navs from "../components/navs/navs";
import "./collect.css";
const myCourse = () => {
  return (
    <div className="mine-page-containe">
      <div className="mi-nav-box">
        <Navs list={["全部", "C++", "Python"]} />
      </div>
      <ul className="mi-my-cour-ul">
        {[1, 2, 31, 11, 1, 1].map((item, index) => {
          return (
            <li className="udr uac" key={index}>
              <img
                className="mi-my-cour-pic"
                src="https://img1.sycdn.imooc.com/655db1a3000181d716000682.jpg"
              />
              <div className="uf1 main-info">
                <div className="main-info-title">请输入课程的名称</div>
                <div className="main-info-subtitle">
                  此处输入对于课程的简单介绍，以及课程带来的学习好处
                </div>
                <div className="main-info-detail">
                  <span className="color-warning">￥499.00</span>
                  <span>
                    <span>人数</span>
                    <span className="color-green">40</span>
                  </span>
                  <span>
                    <span>学习时常</span>
                    <span className="color-green">25h</span>
                  </span>
                  <span className=" cancel-coll">取消收藏</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default myCourse;
