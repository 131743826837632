import React, { useRef, useState } from "react";
import user from "@ass/base/user.png";
import pic from "@ass/index/1.jpg";
import bg from "@ass/classPage/classimg.png";

import { useNavigate } from "react-router-dom";
import styles from "./courseClass.module.css";
const courseClass = () => {
  const [studentIndex, setStudentIndex] = useState(0);
  const [navIndex, setNavIndex] = useState(0);
  const navigate = useNavigate();

  const goStudentDetail = () => {
    navigate("/teacher/index/studentDetail");
  };

  return (
    <div className="udr tea-container">
      <div className="uf1 system-sub">
        <div className="udr uac ujb student-search">
          <div>
            <span>10</span>
            <span>人</span>
          </div>
          <div className="udr uac search-input">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                stroke="#00648E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.0982 24.0611L19.2393 19.2021"
                stroke="#00648E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input placeholder="请输入同学名称" />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="36"
            viewBox="0 0 37 36"
            fill="none"
          >
            <path
              d="M18.4521 6.09277L18.4521 29.9219"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.53711 18.0078H30.3663"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <ul className="student-ul">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
            return (
              <li
                className={`udr uac ${
                  studentIndex === index ? "active" : null
                }`}
                key={index}
              >
                <img src={user} />
                <div>学生姓名</div>
                <span className="te-btn" onClick={goStudentDetail}>
                  查看
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="uf1 udc system-main">
        <div className=" apply-box">
          <div className="apply-title">
            <span className="m-title">系统通知</span>
            <span className="m-notice-num">10</span>
          </div>
          <ul className="apply-ul">
            {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
              return (
                <li className="udr uac ujb" key={index}>
                  <img src={user} className="sq-pic" />
                  <span className="sq-name">张三张三</span>
                  <span className="sq-txt line-hide">请求加入班级...</span>
                  <div>
                    <span className="te-btn">同意</span>
                    <span className="te-btn te-btn-gray">拒绝</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="apply-box te-course-list">
          <div className="udr uac apply-title">
            <div className="udr uac search-input">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.0982 24.0611L19.2393 19.2021"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input placeholder="请输入同学名称" />
            </div>
            <div className="udr uac add-course-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M13.9199 6.53223V22.1701"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.10059 14.3516H21.7385"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>添加课程</span>
            </div>
          </div>
          <ul className="apply-ul">
            {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
              return (
                <li className="udr uac ujb" key={index}>
                  <img src={bg} className={styles.courseBg} />
                  <div className={styles.courseInfo}>
                    <span className="line-hide-2">
                      C++基础课程1基础课程1基础课程1
                    </span>
                  </div>
                  <div>
                    <span className="te-btn">添加学生</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default courseClass;
