import React, { useEffect } from "react";
import Navs from "../../mine/components/navs/navs";
import "./game.css";
import glass from "@ass/OJ/glass.png";
import { useMutation, useQuery } from "react-query";
import request from "@/service/request";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, Divider, List, Skeleton } from "antd";

const Gamne = () => {
  // 获取比赛列表
  const query = useMutation(async () => {
    const res = await request.get("/mmt/competition/list", {
      pageNum: 1,
      pageSize: 20,
    });
    return res.rows;
  });

  useEffect(() => {
    query.mutate();
  }, []);

  return (
    <div className="game-box">
      <Navs list={["全部"]} />
      <div className="game-item">
        <InfiniteScroll
          dataLength={query.data?.length || 0}
          next={query.mutate}
          hasMore={query.data?.length === 20}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain>已经没有更多啦</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={query.data || []}
            renderItem={(item) => (
              <div className="game-list">
                <div className="glass">
                  <img src={glass} alt="" />
                </div>
                <div className="list-content">
                  <div>{item.name}</div>
                  <div>
                    比赛时间
                    <span style={{ color: "#FF8914", marginLeft: "10px" }}>
                      {item.createdTime}
                    </span>
                  </div>
                </div>
                <div className="list-right">
                  <div>进入练习</div>
                </div>
              </div>
            )}
          />
        </InfiniteScroll>
        {/* {query.data?.map((item, index) => {
          return (
            <div className="game-list" key={index}>
              <div className="glass">
                <img src={glass} alt="" />
              </div>
              <div className="list-content">
                <div>{item.name}</div>
                <div>
                  创建时间
                  <span style={{ color: "#FF8914", marginLeft: "10px" }}>
                    {item.createdTime}
                  </span>
                </div>
              </div>
              <div className="list-right">
                <div>进入练习</div>
                <div>删除提单</div>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default Gamne;
