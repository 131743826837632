/* eslint-disable no-dupe-keys */
import React, { useEffect, useRef, useState } from "react";
import { Head } from "@components";
import Swiper from "swiper/js/swiper.js";
import "swiper/css/swiper.min.css";
// import banner1 from "@ass/course/banner1.png";
import Target_1 from "@ass/course/Target_1.png";
import "./course.css";
import { useNavigate } from "react-router-dom";
import request from "@/service/request";

import { Empty, Row, Col } from "antd";
import { useQuery } from "react-query";

const Index = () => {
  const navigate = useNavigate();
  const [coursetypeIndex, setCoursetypeIndex] = useState(0);
  const [courseName, setCourseName] = useState("");
  const swiperContainerHeight = useRef((window.innerWidth / 1920) * 1080);
  // const match = useMatch("/course");

  // 获取课程方向，分类
  const pageInitdata = useQuery(
    [
      "/mmt/coursetype/list",
      "/mmt/direction/list",
      "/mmt/front/page-image/course",
    ],
    async () => {
      const [coursetype, direction, courseBanner] = await Promise.all([
        // 获取课程类别
        request.get("/mmt/coursetype/list", {
          pageNum: 1,
          pageSize: 100,
        }),

        // 获取课程分类
        request.get("/mmt/direction/list", {
          pageNum: 1,
          pageSize: 100,
        }),
        // 获取课程轮播图
        request.get("/mmt/front/page-image", {
          type: "精品课堂图片",
        }),
      ]);
      if (coursetype.rows.length >= 0) {
        coursetype.rows.unshift({
          name: "全部",
        });
      }

      if (direction.rows.length >= 0) {
        direction.rows.unshift({
          name: "不限",
        });
      }
      return {
        coursetype: coursetype.rows,
        direction: direction.rows || [],
        courseBanner: courseBanner.rows || [],
      };
    }
  );

  // 获取课程列表
  const courseListData = useQuery(
    ["/mmt/course/list", courseName],
    async (params) => {
      const res = await request.get("/mmt/course/list", {
        pageNum: 1,
        pageSize: 15,
        typeName: courseName,
        directionName: params.directionName,
      });
      return res;
    }
    // {
    //   // eslint-disable-next-line no-use-before-define
    //   enabled: pageInitdata.isSuccess,
    // }
  );

  useEffect(() => {
    // 配置swiper播放 配置项都写这里面
    new Swiper(".swiper-container", {
      direction: "horizontal", // 轮播图轮播方向，horizontal为横向，
      loop: true, //循环
      autoplay: {
        //自动播放
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false,
        waitForTransition: true,
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".course-button-next",
        prevEl: ".course-button-prev",
      },
      // spaceBetween: 24, //左右间距
      slidesPerView: "auto", //这必须要加，否则返回第一页的时候，右边模块会缺失
      centeredSlides: true, // 每屏，居中显示
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
    });
  }, []);

  return (
    <>
      <Head search={courseListData} />
      <div
        className="udc uac page-container"
        style={{ background: "#EEEEE6", paddingBottom: 200 }}
      >
        <div className="course-carousel">
          <div
            className="course-banner-slide"
            // style={{ height: swiperContainerHeight.current }}
          >
            <img src={pageInitdata.data?.courseBanner[0]?.url} />
          </div>
        </div>
        <div className=" co-course-container">
          <div className="co-class-container">
            <div
              className="classification"
              // style={{ backgroundImage: `url(${class_bg})` }}
            >
              <div className="classification-line-1">
                <img src={Target_1} />
                <span>精品课堂</span>
                <span>好课都在这里...</span>
              </div>
              <div className="classification-line-2 ">
                <span>类别：</span>
                <ul className="udr uac class-list">
                  {pageInitdata.data?.coursetype.map((item, index) => {
                    return (
                      <li
                        className={coursetypeIndex === index ? "active" : ""}
                        key={index}
                        onClick={() => {
                          setCoursetypeIndex(index);
                          const name = item.name === "全部" ? "" : item.name;
                          setCourseName(name);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <div className="classification-line-2 classification-line-3">
            <span className="lab">分类：</span>
            <ul className="udr class-list">
              {pageInitdata.data?.direction?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={directionIndex === index ? "active" : ""}
                    onClick={() => {
                      setDirectionIndex(index);
                    }}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div> */}
            </div>
          </div>
          {/* <div className="udr ujb course-search">
          <ul className="udr uac course-search-ul">
            <li className="active">默认排序</li>
            <li>最新</li>
            <li>热门</li>
          </ul>
          <div className="udr co-pagination">
            <Pagination
              defaultCurrent={1}
              defaultPageSize={15}
              total={courseListData.data?.total || 1}
            />
          </div>
        </div> */}
          <div className="co-course-ul-container">
            {courseListData.data?.rows?.length ? (
              <Row gutter={[16, 16]}>
                {courseListData.data?.rows?.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={8}
                      xxl={6}
                      onClick={() => navigate(`/course/detail/${item.id}`)}
                    >
                      <div
                        className="udc co-course-li-info"
                        style={{
                          backgroundImage: `url(${item.coverPath})`,
                        }}
                      >
                        <span>{item.name}</span>
                        <span>{item.createdTime}开课</span>
                        <span>新课</span>
                      </div>
                      <div className="udr ujb uac co-course-item-fo">
                        <span className="co-course-price">
                          {item.price > 0 ? "￥" + item.price : "免费"}
                        </span>
                        <div className="udr"></div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div style={{ padding: "100px 0" }}>
                <Empty description="暂无数据" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
