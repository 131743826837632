import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        {
          controls: true,
          aspectRatio: "16:9",
          autoplay: true, //加载完成是否自动播放
          // muted: true, //
          loop: false, //视频播放结束后，是否循环播放
          notSupportedMessage: "此视频暂无法播放，请稍后再试",
          controlBar: {
            timeDivider: true, //是否显示时间控制条，默认为true
            // remainingTimeDisplay: false, //是否显示剩余时间，默认为true
            // fullscreenToggle: true, // 全屏按钮
            children: [
              //自定义
              { name: "playToggle" }, // 播放按钮
              {
                name: "volumePanel", // 音量控制
                inline: false, // 不使用水平方式
              },
              { name: "currentTimeDisplay" }, // 当前已播放时间
              { name: "durationDisplay" }, // 总时间
              { name: "progressControl" }, // 播放进度条
              {
                name: "pictureInPictureToggle", //支持画中画
              },
              {
                name: "FullscreenToggle", //支持全屏
              },
            ],
          },
        },
        () => {
          console.log("player is ready");
          onReady && onReady(player);
        }
      ));
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player className="video_play" style={{ height: "100%" }}>
      <video
        ref={videoRef}
        autoPlay={true}
        className="video-js vjs-big-play-centered"
        muted={props.muted}
      ></video>
    </div>
  );
};

export default VideoJS;
