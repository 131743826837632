import React, { useEffect, useRef, useState } from "react";

import styles from "./school.module.css";
import { useQuery } from "react-query";
import request from "@/service/request";
import { getLocalUser } from "@/utils";
import { Empty } from "antd";
import user from "@ass/base/user.png";
import Create from "../components/CreateClass"; // 创建老师学生
import CreateSchool from "../components/CreateSchool"; // 创建学校
import Delete from "../components/DeleteClass"; // 删除老师

import { STUDENT, TEACHER, INSTITUTION } from "@/config";
const roleObj = {
  [STUDENT]: "学生",
  [TEACHER]: "老师",
  [INSTITUTION]: "代理人",
};
const School = () => {
  const [navIndex, setNavIndex] = useState(0);
  const CreateClassRef = useRef(null); // 创建老师学生
  const CreateSchoolRef = useRef(null); // 创建学校
  const DeleteClassRef = useRef(null); // 删除老师
  const inputRef = useRef(null);
  const userinfo = useRef({});

  useEffect(() => {
    const userInfo = getLocalUser();
    userinfo.current = userInfo;
  }, []);

  // 获取自己创建的群组
  const getList = useQuery(["/mmt/group/list"], async () => {
    const res = await request.get(`/mmt/group/list`, {
      pageNum: 1,
      pageSize: 100,
      creatorId: userinfo.current.userId,
    });
    return res.rows;
  });

  // 获取当前选择的群组下的人员
  const groupuser = useQuery(
    ["/mmt/groupuser/list", navIndex],
    async () => {
      console.log(getList.data[navIndex].id);
      const res = await request.get(`/mmt/groupuser/list`, {
        pageNum: 1,
        pageSize: 100,
        groupId: getList.data[navIndex].id,
      });
      return res.rows;
    },
    {
      enabled: getList.isSuccess,
    }
  );

  useEffect(() => {}, []);

  const showCreateModal = () => {
    CreateSchoolRef.current.open();
  };

  return (
    <>
      <div className="te-sub-nav udr uac">
        {getList.data?.length ? (
          <ul className="my-manage-class-list">
            {getList.data.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    navIndex === index ? "active udr uac ujb" : "udr uac ujb"
                  }
                  style={{ padding: "0 10px" }}
                  onClick={() => {
                    setNavIndex(index);
                  }}
                >
                  <span>{item.name}</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      DeleteClassRef.current.open(item.id);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.5 5H4.16667H17.5"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.33301 9.16699V14.167"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.667 9.16699V14.167"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
              );
            })}
          </ul>
        ) : (
          <Empty />
        )}

        <div className="udr uac ujc te-create-class" onClick={showCreateModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
          >
            <path
              d="M13.418 6.21484V21.8527"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.59863 14.0342H21.2365"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>创建群组</span>
        </div>
      </div>
      <div className="uf1 udr child-view">
        <div className={styles.schCon}>
          <div className={"udr uac " + styles.searchCon}>
            {/* <div className={styles.title}>
              <span>10</span>
              <span>人</span>
            </div> */}
            <div className={"udr uac " + styles.inputCon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.0982 24.0611L19.2393 19.2021"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                ref={inputRef}
                placeholder="请输入用户名称"
                // onKeyDown={}
                onInput={(e) => {
                  console.log(e);
                }}
              />
            </div>
            <div
              className={"udr uac ujc " + styles.addBtn}
              onClick={() => {
                CreateClassRef.current.open();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 8V16"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 12H16"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>添加人员</span>
            </div>
          </div>
          <div className={styles.listCon}>
            {groupuser.data?.length ? (
              <ul>
                {groupuser.data.map((item, index) => {
                  return (
                    <li className="udr uac ujc" key={index}>
                      <img src={user} />
                      <div className={"udc uf1 " + styles.username}>
                        <span>{item.userName}</span>
                        <span>{roleObj[item.roleName]}</span>
                      </div>
                      <svg
                        className="curp"
                        onClick={() => {
                          DeleteClassRef.current.open(item.id);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M2.5 5H4.16667H17.5"
                          stroke="#00648E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.8337 4.99935V16.666C15.8337 17.108 15.6581 17.532 15.3455 17.8445C15.0329 18.1571 14.609 18.3327 14.167 18.3327H5.83366C5.39163 18.3327 4.96771 18.1571 4.65515 17.8445C4.34259 17.532 4.16699 17.108 4.16699 16.666V4.99935M6.66699 4.99935V3.33268C6.66699 2.89065 6.84259 2.46673 7.15515 2.15417C7.46771 1.84161 7.89163 1.66602 8.33366 1.66602H11.667C12.109 1.66602 12.5329 1.84161 12.8455 2.15417C13.1581 2.46673 13.3337 2.89065 13.3337 3.33268V4.99935"
                          stroke="#00648E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.33301 9.16602V14.166"
                          stroke="#00648E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.667 9.16602V14.166"
                          stroke="#00648E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="uf1 udc uac ujc">
                <Empty />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 创建学校 */}
      <CreateSchool
        ref={CreateSchoolRef}
        userinfo={userinfo.current}
        query={() => {
          getList.refetch();
          groupuser.refetch();
        }}
      />
      {/* 学校下添加人员 */}
      <Create
        ref={CreateClassRef}
        userinfo={userinfo.current}
        groupId={getList.data && getList.data[navIndex]?.id}
        query={groupuser}
      />

      <Delete ref={DeleteClassRef} query={groupuser} />
    </>
  );
};

export default School;
