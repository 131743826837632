import React, { useRef, useState } from "react";
import user from "@ass/base/user.png";
import pic from "@ass/index/1.jpg";
import { Outlet } from "react-router-dom";

const myClass = () => {
  const [studentIndex, setStudentIndex] = useState(0);
  const [navIndex, setNavIndex] = useState(0);



  return (
    <>
      <div className="te-sub-nav">
        <ul className="my-manage-class-list">
          {[1, 2, 3, 4].map((item, index) => {
            return (
              <li
                key={index}
                className={navIndex === index ? "active" : ""}
                onClick={() => {
                  setNavIndex(index);
                }}
              >
                <span>C++第一次比赛</span>
                {/*<div className="bag">10</div>*/}
              </li>
            );
          })}
        </ul>
        <div className="udr uac ujc te-create-class" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
          >
            <path
              d="M13.418 6.21484V21.8527"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.59863 14.0342H21.2365"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>创建比赛</span>
        </div>
      </div>
      <div className="uf1 child-view">
        <Outlet />
      </div>
    </>
  );
};

export default myClass;
