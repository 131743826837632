import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import "./myclass.css";
import CreateClass from "../components/CreateClass";
import DeleteClass from "../components/DeleteClass";
import { Outlet } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import request from "@/service/request";
import { getLocalUser } from "@/utils";
import { Empty } from "antd";

const classContext = createContext();

const myClass = () => {
  const [navIndex, setNavIndex] = useState(0);
  const CreateClassRef = useRef(null);
  const DeleteClassRef = useRef(null);
  const userinfo = useRef({});
  const [classinfo, setClassinfo] = useState({});

  useEffect(() => {
    const userInfo = getLocalUser();
    userinfo.current = userInfo;
  }, []);

  //
  const getList = useQuery(
    ["/mmt/classes/list/" + userinfo.current.userId],
    async () => {
      const res = await request.get(`/mmt/classes/list`, {
        pageNum: 1,
        pageSize: 100,
        belongUserid: userinfo.current.userId,
      });
      if (res.rows.length) {
        const classId = localStorage.getItem("classId");
        if (classId) {
          const index = res.rows.findIndex((item) => item.id === classId);
          console.log("index", index);
          if (index !== -1) {
            setNavIndex(index);
            setClassinfo({ id: res.rows[index].id, bh: res.rows[0].bh });
          }
        } else {
          setClassinfo({ id: res.rows[0].id, bh: res.rows[0].bh });
        }
      }
      return res.rows;
    },
    {
      cacheTime: 0, // 过期时间
    }
  );

  const showCreateModal = () => {
    CreateClassRef.current.open();
  };

  return (
    <classContext.Provider value={{ ...classinfo }}>
      <div className="te-sub-nav udr uac">
        {getList.data?.length ? (
          <ul className="my-manage-class-list">
            {getList.data.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    navIndex === index ? "active udr uac ujb" : "udr uac ujb"
                  }
                  style={{ padding: "0 10px" }}
                  onClick={() => {
                    setClassinfo({ id: item.id, bh: item.bh });
                    localStorage.setItem("classId", item.id);
                    setNavIndex(index);
                  }}
                >
                  <span>{item.name}</span>
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      DeleteClassRef.current.open(item.id);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.5 5H4.16667H17.5"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.8337 5.00033V16.667C15.8337 17.109 15.6581 17.5329 15.3455 17.8455C15.0329 18.1581 14.609 18.3337 14.167 18.3337H5.83366C5.39163 18.3337 4.96771 18.1581 4.65515 17.8455C4.34259 17.5329 4.16699 17.109 4.16699 16.667V5.00033M6.66699 5.00033V3.33366C6.66699 2.89163 6.84259 2.46771 7.15515 2.15515C7.46771 1.84259 7.89163 1.66699 8.33366 1.66699H11.667C12.109 1.66699 12.5329 1.84259 12.8455 2.15515C13.1581 2.46771 13.3337 2.89163 13.3337 3.33366V5.00033"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.33301 9.16699V14.167"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.667 9.16699V14.167"
                      stroke="#888B9B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
              );
            })}
          </ul>
        ) : (
          <Empty />
        )}

        <div className="udr uac ujc te-create-class" onClick={showCreateModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
          >
            <path
              d="M13.418 6.21484V21.8527"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.59863 14.0342H21.2365"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>创建班级</span>
        </div>
      </div>
      <div className="uf1 child-view">
        <Outlet />
      </div>
      <CreateClass
        ref={CreateClassRef}
        userinfo={userinfo.current}
        query={getList}
      />
      <DeleteClass ref={DeleteClassRef} query={getList} />
    </classContext.Provider>
  );
};
const useClassContext = () => {
  const currentContext = useContext(classContext);
  return currentContext;
};
export { useClassContext };
export default myClass;
