import React, { useEffect, useRef, useState } from "react";
// import "./myclass.css";
import "./te-myclass.css";
import CreateClass from "../components/CreateClass";
import DeleteClass from "../components/DeleteClass";
import { useQuery, useMutation } from "react-query";
import request from "@/service/request";
import { getLocalUser } from "@/utils";
import { Empty } from "antd";
import teacherPic from "@ass/mine/teacher.png";
import enpty1 from "@ass/mine/enpty1.png";
import styles from "./myclass.module.css";
import courseBg from "@ass/classPage/classimg.png";
import { useNavigate } from "react-router-dom";

const myClass = () => {
  const [navIndex, setNavIndex] = useState(0);
  const CreateClassRef = useRef(null);
  const DeleteClassRef = useRef(null);
  const userinfo = useRef({});
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = getLocalUser();
    userinfo.current = userInfo;
  }, []);

  // 获取我的班级列表
  const getList = useQuery(["/mmt/mineclasses/list"], async () => {
    const res = await request.get(`/mmt/mineclasses/list`, {
      pageNum: 1,
      pageSize: 50,
      userId: userinfo.current.userId,
      approved: "1",
    });
    return res.rows;
  });

  // 获取我加入班级后，班级课程我是否能够观看
  const getClassCourse = useQuery(
    ["/mmt/classescourse/studentClassCourse", navIndex],
    async () => {
      const res = await request.post(`/mmt/classescourse/studentClassCourse`, {
        pageNum: 1,
        pageSize: 50,
        classesId: getList.data[navIndex].mmtClasses.id,
        userId: userinfo.current.userId,
      });
      return res.data;
    },
    {
      enabled: getList.isSuccess,
    }
  );

  const showCreateModal = () => {
    CreateClassRef.current.open();
  };

  return (
    <>
      <div className="udr uac ujc" style={{ height: "100%" }}>
        <div className="te-sub-nav udc uac">
          {getList.data?.length ? (
            <ul className="my-manage-class-list my-class-student-ul">
              {getList.data.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={
                      navIndex === index ? "active udr uac ujb" : "udr uac ujb"
                    }
                    onClick={() => {
                      setNavIndex(index);
                    }}
                  >
                    <span>{item.mmtClasses?.name}</span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="uf1 udc uac ujc">
              <Empty />
            </div>
          )}
          <div
            className="udr uac ujc mi-create-class-btn"
            onClick={showCreateModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
            >
              <path
                d="M13.418 6.21484V21.8527"
                stroke="#00648E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.59863 14.0342H21.2365"
                stroke="#00648E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>加入班级</span>
          </div>
        </div>
        <div className="uf1 udc three-route">
          {getList.data?.length ? (
            <div className={"udc uf1 " + styles.classInfo}>
              <div className={"udr uac " + styles.teInfo}>
                <img src={teacherPic} />
                <span className={styles.teName}>
                  {getList.data &&
                    getList.data[navIndex]?.mmtClasses.belongUsername}
                </span>
                <span className={styles.teSubName}>
                  这位老师很懒，什么都没留下~
                </span>
              </div>
              <div className={"udc uf1 " + styles.classCourseList}>
                <div className={styles.classTit}>班级课程</div>
                {getClassCourse.data?.length ? (
                  <ul className={styles.courseUl}>
                    {getClassCourse.data.map((item, index) => {
                      return (
                        <li className="udr uac" key={index}>
                          <img
                            src={
                              item.mmtCourse.coverPath
                                ? item.mmtCourse.coverPath
                                : courseBg
                            }
                          />
                          <div className={styles.courseName}>
                            {item.mmtCourse.name}
                          </div>
                          <span
                            className={styles.courseBtn}
                            onClick={() => {
                              navigate(
                                `/course/play/${item.courseId}/${0}/${0}`
                              );
                            }}
                          >
                            学习
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className={"uf1 udc ujc "}>
                    <Empty />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ClassEmpty />
          )}
        </div>
        <CreateClass
          ref={CreateClassRef}
          userinfo={userinfo.current}
          query={getList}
        />
      </div>
      <DeleteClass ref={DeleteClassRef} query={getList} />
    </>
  );
};

const ClassEmpty = () => {
  return (
    <div className="uf1 udc uac ujc">
      <div
        className="udr uac ujc"
        style={{
          width: 100,
          height: 100,
          padding: 10,
          background: "#DCE2CB",
          borderRadius: 160,
        }}
      >
        <img style={{ width: "100%" }} src={enpty1} />
      </div>
      <span
        style={{
          color: "#000",
          fontSize: "0.8rem",
          fontWeight: 400,
          marginTop: "0.5rem",
        }}
      >
        还没有加入任何班级哦~
      </span>
    </div>
  );
};

export default myClass;
