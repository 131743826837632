import React from "react";
import enpty from "@ass/mine/enpty.png";
const Empty = ({ txt = "还没有加入任何训练哦~", image = enpty }) => {
  return (
    <div className={"udc uac ujc"} style={{ flex: 1 }}>
      <div
        className="udr uac ujc"
        style={{
          width: 150,
          height: 150,
          borderRadius: 90,
          background: "#dce2cb",
        }}
      >
        <img src={image} style={{ width: 120, height: 120 }} />
      </div>
      {typeof txt === "string" ? (
        <span
          style={{
            color: "#000",
            fontSize: 20,
            fontWeight: 400,
            marginTop: 20,
          }}
        >
          {txt}
        </span>
      ) : (
        txt
      )}
    </div>
  );
};

export default Empty;
