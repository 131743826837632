import React, { useEffect, useRef, useState } from "react";

import { Input, Button } from "antd";
const { TextArea } = Input;
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import ajax from "@/service/request";
import "./detail.less";

export default function Topic() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  const [formObj, setFormObj] = useState({
    bankId: searchParams.get("bankId"),
    name: "",
    description: "",

    inputFormat: "",
    outputFormat: "",

    sampleInput: "",
    sampleOutput: "",

    tip: "",
    timeRestriction: "",
    spaceRestriction: "",
    standardAnswer: "",
    remark: "",
  });

  const checkpointStatus = useRef("add");

  const inputChange = (e) => {
    setFormObj({
      ...formObj,
      [e.target.name]: e.target.value,
    });
  };

  // 题目详情
  const detailDta = useQuery(
    ["/mmt/question/get"],
    async () => {
      const res = await ajax.get(`/mmt/question/get/${searchParams.get("id")}`);
      console.log(res, 111);
      setFormObj(res.data);
      return res.data;
    },
    {
      enabled: Boolean(searchParams.get("id")),
    }
  );

  useEffect(() => {
    if (detailDta.data) {
      detailDta.refetch();
    }
  }, []);

  return (
    <div className="topic-root">
      <div className="topic-head udr uac">
        <div className="curp udr uac" onClick={() => {navigate(-1)}}>
          <svg
            className="curp"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>题目详情</span>
        </div>
      </div>
      <div className="topic-page">
        <div className="topic-left">
          <div className="topic-title">{formObj?.name}</div>
          <div className="topic-item">
            <div className="tit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="6" stroke="#7371B6" strokeWidth="2" />
              </svg>
              题目描述：
            </div>
            <div className="topic-txt">{formObj?.description}</div>
          </div>
          <div className="topic-item">
            <div className="tit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="6" stroke="#7371B6" strokeWidth="2" />
              </svg>
              输入格式：
            </div>
            <div className="topic-txt">{formObj?.inputFormat}</div>
          </div>
          <div className="topic-item">
            <div className="tit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="6" stroke="#7371B6" strokeWidth="2" />
              </svg>
              输出格式：
            </div>
            <div className="topic-txt">{formObj?.outputFormat}</div>
          </div>
          <div className="topic-item">
            <div className="tit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="6" stroke="#7371B6" strokeWidth="2" />
              </svg>
              样例输入：
            </div>
            <TextArea
              className="TextArea"
              rows={4}
              placeholder="样例输入："
              name="sampleOutput"
              value={formObj?.sampleInput || ""}
              onChange={inputChange}
            />
          </div>
          <div className="topic-item">
            <div className="tit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="6" stroke="#7371B6" strokeWidth="2" />
              </svg>
              样例输出：
            </div>
            <TextArea
              className="TextArea"
              rows={4}
              placeholder="请输入样例输出"
              name="sampleOutput"
              value={formObj?.sampleOutput || ""}
              onChange={inputChange}
            />
          </div>
          <div className="topic-item">
            <div className="tit">提示：</div>
            <div className="topic-txt">{formObj?.tip || "暂无提示"}</div>
          </div>
          <div className="topic-item start-time">
            <div>
              <svg
                className="label-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="6" stroke="#7371B6" strokeWidth="2" />
              </svg>
              限制：
            </div>
            <div className="label-val">
              时间限制: {formObj?.timeRestriction || ""}ms
            </div>
            <div className="label-val">
              空间限制: {formObj?.spaceRestriction || ""}MB
            </div>
          </div>
        </div>
        <div className="topic-right">
          <div className="topic-rightT">
            <div className="tit">C++</div>
            <TextArea
              className="TextArea"
              rows={4}
              placeholder="请在此处粘贴代码..."
              name="standardAnswer"
              value={formObj?.standardAnswer || ""}
              onChange={inputChange}
            />
          </div>
          <div className="topic-rightA udr uac uje">
            <Button type="text" className="topic-btn1">
              历史提交
            </Button>
            <Button type="text" className="topic-btn2">
              查看题解
            </Button>
            <Button type="text" className="topic-btn2">
              提交
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
