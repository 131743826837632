import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Head } from "@components";
import nav2 from "@ass/mine/nav2.png";
import nav3 from "@ass/mine/nav3.png";
import nav4 from "@ass/mine/nav4.png";
import nav7 from "@ass/mine/nav7.svg";
import nav_bg from "@ass/mine/nav-bg.png";

import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";

const navList = [
  {
    txt: "题库",
    icon: nav2,
    pathname: "/oj/questionBank",
  },
  {
    txt: "比赛",
    icon: nav4,
    pathname: "/oj/game",
  },
  {
    txt: "状态",
    icon: nav7,
    pathname: "/oj/state",
  },
  {
    txt: "用户",
    icon: nav3,
    pathname: "/oj/user",
  },
  {
    txt: "FAQ",
    icon: nav4,
    pathname: "/oj/faq",
  },
  // {
  //   txt: "我的收藏",
  //   icon: nav5,
  //   pathname: "/mine/collect",
  // },
  // {
  //   txt: "购物车",
  //   icon: nav6,
  // },
];

const Index = () => {
  const [navIndex, setNavIndex] = useState(0);
  const navigate = useNavigate();
  const localtion = useLocation();
  useEffect(() => {
    console.log(localtion);
    navList.forEach((item, index) => {
      if (item.pathname === localtion.pathname) {
        setNavIndex(index);
      }
    });
  }, [localtion]);
  const routeChange = (item, index) => {
    if (item.pathname) {
      navigate(item.pathname);
      setNavIndex(index);
    }
  };

  return (
    <div className="page-container mine-container ">
      <Head />

      <div className="udr oj-main ">
        <ul className="my-nav-list">
          {navList.map((item, index) => {
            return (
              <li
                key={index}
                className={navIndex === index ? "active" : ""}
                onClick={() => {
                  routeChange(item, index);
                }}
              >
                <div className="mine-nav-icon">
                  <img src={item.icon} />
                </div>
                <span>{item.txt}</span>
                <img src={nav_bg} />
              </li>
            );
          })}
        </ul>
        <div className="uf1 child-view">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Index;
