/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Head, RightFloat, Videojs } from "@components";
import "./play.css";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import request from "@/service/request";
import { Modal, message } from "antd";
const Detail = () => {
  const params = useParams();
  const [liindex, setLiindex] = useState(Number(params.index));
  const navigate = useNavigate();
  const playerRef = useRef(null);

  const [courseIndex, setCourseIndex] = useState(params.index); // 章节索引
  const [subsectionIndex, setSubsectionIndex] = useState(params.c_index); //小节索引

  const detailData = useQuery([`/mmt/course/menu/${params.id}`], async () => {
    const [res] = await Promise.all([
      request.get(`/mmt/course/menu/${params.id}`),
    ]);
    const mmtCourse = res.data.mmtCourse || {};
    vedioPlay(res.data);
    return {
      course: mmtCourse,
      chapterDTOs: res.data.chapterDTOs,
    };
  });

  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const vedioPlay = (data) => {
    const c_index = Number(params.c_index || 0);
    const index = Number(params.index || 0);
    if (!data.chapterDTOs?.length) {
      Modal.warning({
        title: "警告！",
        content: "当前课程没有视频，请选择其它课程学习！",
        onOk: () => {
          navigate(-1);
        },
      });
      return;
    }
    console.log(1);
    const itemRes = data.chapterDTOs[index].sessions[c_index];
    if (itemRes === undefined) {
      message.error("当前小节没有视频");
      return;
    }
    console.log(2);
    setCourseIndex(index);
    setSubsectionIndex(c_index);
    if (itemRes?.m3u8Url) {
      playerRef.current.src(itemRes.m3u8Url);
    } else if (itemRes.videoPath) {
      playerRef.current.src(itemRes.videoPath);
    } else {
      message.error("当前小节没有视频");
    }
    // playerRef.current.play();
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("waiting", () => {
      console.log("player is waiting");
    });
    if (detailData.data) {
      vedioPlay(detailData.data);
    }
    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  const changePlay = (item) => {
    if (item.m3u8Url) {
      playerRef.current.src(item.m3u8Url);
    } else if (item.videoPath) {
      playerRef.current.src(item.videoPath);
    }
    playerRef.current.play();
  };

  return (
    <div className="page-container" style={{ paddingBottom: 0 }}>
      <Head />
      {/* 面包屑 */}
      <div className="breadcrumbs pl-bread">
        <span
          className="curp"
          onClick={() => {
            navigate(-2);
          }}
        >
          精品课堂
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
        >
          <path
            d="M1.0651 0.173901C0.948263 0.0610542 0.791775 -0.00138808 0.629344 2.34192e-05C0.466912 0.00143491 0.311534 0.0665872 0.196673 0.181448C0.0818124 0.296309 0.0166599 0.451687 0.0152484 0.614119C0.0138369 0.77655 0.0762793 0.933038 0.189127 1.04988L4.13164 4.99239L0.189127 8.9349C0.129958 8.99204 0.0827626 9.0604 0.0502951 9.13599C0.0178276 9.21157 0.000738188 9.29286 2.33907e-05 9.37512C-0.000691406 9.45737 0.0149831 9.53895 0.0461322 9.61508C0.0772813 9.69122 0.123281 9.76039 0.181448 9.81855C0.239614 9.87672 0.308783 9.92272 0.384918 9.95387C0.461052 9.98502 0.542628 10.0007 0.624885 9.99998C0.707142 9.99926 0.788433 9.98217 0.864015 9.94971C0.939597 9.91724 1.00796 9.87004 1.0651 9.81087L5.44374 5.43285L5.4456 5.43037C5.56174 5.3142 5.62698 5.15666 5.62698 4.99239C5.62698 4.82812 5.56174 4.67057 5.4456 4.5544"
            fill="black"
          />
        </svg>
        <span>课程详情</span>
      </div>
      <div className="pl-body">
        <div className="play-info">
          <div className="play-info-left">
            {/* <video
              ref={videoRef}
              onPlay={() => {
                console.log("播放");
                setVideoControls(true);
              }}
              onPause={() => {
                setVideoControls(false);
                console.log("暂停");
              }}
              controls={videoControls}
              src={
                detailData.data?.chapterDTOs[courseIndex].sessions[
                  subsectionIndex
                ]?.videoPath
              }
            />
            {videoRef.current?.paused && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="77"
                height="77"
                viewBox="0 0 77 77"
                fill="none"
                className="curp"
                onClick={() => {
                  videoRef.current.play();
                }}
              >
                <path
                  d="M6.36279 38.4995C6.36279 42.7125 7.19261 46.8843 8.80485 50.7766C10.4171 54.6689 12.7802 58.2055 15.7593 61.1846C18.7383 64.1636 22.2749 66.5267 26.1673 68.139C30.0596 69.7512 34.2313 70.581 38.4443 70.581C42.6573 70.581 46.8291 69.7512 50.7214 68.139C54.6137 66.5267 58.1503 64.1636 61.1294 61.1846C64.1084 58.2055 66.4715 54.6689 68.0838 50.7766C69.696 46.8843 70.5259 42.7125 70.5259 38.4995C70.5259 29.991 67.1459 21.8309 61.1294 15.8144C55.1129 9.79798 46.9529 6.41797 38.4443 6.41797C29.9358 6.41797 21.7757 9.79798 15.7593 15.8144C9.7428 21.8309 6.36279 29.991 6.36279 38.4995Z"
                  fill="#666767"
                />
                <path
                  d="M37.8652 26.1406C35.2933 23.8147 31.1821 25.6397 31.1821 29.1074V47.7822C31.1821 51.2499 35.2932 53.0749 37.8651 50.749L48.1899 41.4116C49.9459 39.8235 49.9459 37.0662 48.19 35.4781L37.8652 26.1406Z"
                  fill="white"
                />
              </svg>
            )} */}
            <Videojs
              className="play-videojs"
              onReady={handlePlayerReady}
              // muted={true}
            />
          </div>
          <div className="play-info-right">
            <ul className="directory-list">
              {detailData.data?.chapterDTOs.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${index === liindex ? "active" : ""}`}
                  >
                    <div
                      className="udr uac directory-item"
                      onClick={() => {
                        setLiindex(index);
                      }}
                    >
                      <span className="name">
                        {item?.mmtCourseChapter.name}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="12"
                        viewBox="0 0 20 12"
                        fill="none"
                      >
                        <path
                          d="M0.347803 2.1302C0.122108 1.89653 -0.00277615 1.58355 4.68383e-05 1.25869C0.00286983 0.933825 0.133174 0.623068 0.362896 0.393346C0.592617 0.163625 0.903375 0.0333198 1.22824 0.0304968C1.5531 0.0276738 1.86608 0.152559 2.09975 0.378253L9.98477 8.26327L17.8698 0.378253C17.9841 0.259916 18.1208 0.165525 18.272 0.10059C18.4231 0.0356553 18.5857 0.00147638 18.7502 4.67814e-05C18.9147 -0.00138281 19.0779 0.0299661 19.2302 0.0922644C19.3824 0.154563 19.5208 0.246562 19.6371 0.362895C19.7534 0.479229 19.8454 0.617566 19.9077 0.769835C19.97 0.922104 20.0014 1.08526 20 1.24977C19.9985 1.41428 19.9643 1.57687 19.8994 1.72803C19.8345 1.87919 19.7401 2.01591 19.6217 2.1302L10.8657 10.8875L10.8607 10.8912C10.6284 11.1235 10.3133 11.254 9.98477 11.254C9.65624 11.254 9.34115 11.1235 9.1088 10.8912"
                          fill="white"
                        />
                      </svg>
                    </div>

                    <div className="vedio-play-info">
                      {item.sessions?.map((c_item, c_index) => {
                        return (
                          <div
                            className="udr uac no-item"
                            key={c_index + "" + index}
                            onClick={() => {
                              setCourseIndex(index);
                              setSubsectionIndex(c_index);
                              changePlay(c_item);
                            }}
                          >
                            {subsectionIndex === c_index ? (
                              <svg
                                className="play-ani"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="30"
                                viewBox="0 0 25 30"
                                fill="none"
                              >
                                <path
                                  d="M10 2.5C10 1.11929 11.1193 0 12.5 0C13.8807 0 15 1.11929 15 2.5V27.5C15 28.8807 13.8807 30 12.5 30C11.1193 30 10 28.8807 10 27.5V2.5Z"
                                  fill="#71B07B"
                                />
                                <path
                                  d="M20 6.66667C20 5.28596 21.1193 4.16667 22.5 4.16667C23.8807 4.16667 25 5.28595 25 6.66667V23.3333C25 24.714 23.8807 25.8333 22.5 25.8333C21.1193 25.8333 20 24.714 20 23.3333V6.66667Z"
                                  fill="#71B07B"
                                />
                                <path
                                  d="M0 6.66667C0 5.28596 1.11929 4.16667 2.5 4.16667C3.88071 4.16667 5 5.28595 5 6.66667V23.3333C5 24.714 3.88071 25.8333 2.5 25.8333C1.11929 25.8333 0 24.714 0 23.3333V6.66667Z"
                                  fill="#71B07B"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <path
                                  d="M2.2373 15C2.2373 16.676 2.56742 18.3356 3.2088 19.884C3.85019 21.4325 4.79028 22.8394 5.9754 24.0245C7.16052 25.2096 8.56747 26.1497 10.1159 26.7911C11.6643 27.4325 13.3239 27.7626 15 27.7626C16.676 27.7626 18.3356 27.4325 19.884 26.7911C21.4325 26.1497 22.8394 25.2096 24.0245 24.0245C25.2096 22.8394 26.1497 21.4325 26.7911 19.884C27.4325 18.3356 27.7626 16.676 27.7626 15C27.7626 11.6151 26.418 8.36886 24.0245 5.9754C21.6311 3.58194 18.3848 2.2373 15 2.2373C11.6151 2.2373 8.36886 3.58194 5.9754 5.9754C3.58194 8.36886 2.2373 11.6151 2.2373 15Z"
                                  fill="#666767"
                                />
                                <path
                                  d="M13.7816 9.1897C13.1386 8.60821 12.1108 9.06446 12.1108 9.93138V20.0245C12.1108 20.8914 13.1386 21.3477 13.7816 20.7662L19.3618 15.7196C19.8008 15.3226 19.8008 14.6333 19.3618 14.2363L13.7816 9.1897Z"
                                  fill="white"
                                />
                              </svg>
                            )}

                            <span className="no-name no-play">
                              {c_item.name}
                            </span>
                            {/* <span className="no-minute">109分钟</span> */}
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="pl-title">{detailData.data?.course.name}</div>
        <div className="pl-sub-title">{detailData.data?.course.detail}</div>
      </div>
      <div className="de-footer pl-footer">
        <div className="udr uac ujc nav-list pl-nav-list">
          <div className="active">课程介绍</div>
          {/* <div>学习评论</div> */}
        </div>

        <div className="course-introduction">
          <div className="title">讲师介绍</div>
          <div className="udr uac teacher-info">
            <img src={detailData.data?.course.teachImage} />
            <div className="udc teacher-c">
              <span>{detailData.data?.course.teachName}</span>
              <span>讲师</span>
            </div>
          </div>
          <div className="sub">{detailData.data?.course.teachIntro}</div>
          <div className="course-details">课程详情</div>
          <div className="course-details-sub">
            {detailData.data?.course.detail}
          </div>
        </div>
        {/* <div className="comment-introduction">
          <div className="udr uac">
            <div className="pl-commo">
              <span>评论</span>
              <span>2049</span>
            </div>
            <div className="pl-search">
              <span>最热</span>
              <span />
              <span>最新</span>
            </div>
          </div>
          <div className="udr uac user-comment">
            <img
              className="userpic"
              src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1796273328,1072574788&fm=253&gp=0.jpg"
            />
            <Input
              style={{
                marginLeft: 24,
                padding: 0,
                height: 60,
                paddingLeft: 11,
                paddingRight: 11,
                borderColor: "#71B07B",
                borderWidth: 2,
              }}
            />
            <Button
              type="primary"
              style={{
                width: 120,
                height: 60,
                borderRadius: 12,
                background: "#71B07B",
                marginLeft: 16,
              }}
            >
              发布
            </Button>
          </div>
          <ul className="comment-list pl-comment-list">
            {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
              return (
                <li className="udr" key={index}>
                  <img
                    className="userpic"
                    src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1796273328,1072574788&fm=253&gp=0.jpg"
                  />
                  <div className="udc userinfo">
                    <div className="udr uac ujb">
                      <span className="username">用户名称</span>
                      <span className="report">举报</span>
                    </div>
                    <div className="udr uac">
                      <span className="usertime">
                        5天前|已上课4天16小时19分时评论
                      </span>
                      <Rate value={3} />
                    </div>
                    <div className="comment">
                      这个课程实在是太棒啦！老师讲的很好哦，感觉自己又成长了哦！
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="pl-list-floter-tip">仅有打分、无文字评价未做展示</div>
          <div className="pl-pagination">
            <Pagination defaultCurrent={1} total={50} />
          </div>
        </div> */}
      </div>
      <RightFloat />
    </div>
  );
};
export default Detail;
