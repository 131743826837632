/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import Navs from "../components/navs/navs";
import { Empty } from "@components";
import "./myCourse.css";
import { Button } from "antd";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getLocalUser } from "@/utils";

import request from "@/service/request";

const myCourse = () => {
  const navigate = useNavigate();
  const userinfo = useRef({});

  useEffect(() => {
    userinfo.current = getLocalUser();
  }, []);

  // 获取课程列表
  const courseListData = useQuery(["/mmt/minecourse/list"], async () => {
    const res = await request.get("/mmt/minecourse/list", {
      pageNum: 1,
      pageSize: 50,
      userId: userinfo.current.userId,
    });
    console.log(res.rows);
    return res.rows;
  });

  return (
    <div className="udc course-page-containe">
      <div className="mi-nav-box">
        <Navs
          onChange={(i) => {
            changeCourse(i);
          }}
          list={["全部"]}
        />
      </div>
      {courseListData.data?.length ? (
        <ul className="mine-my-cour-ul">
          {courseListData.data?.map((item, index) => {
            return (
              <li className="udr uac" key={index}>
                <img className="mi-my-cour-pic" src={item.courseCoverPath} />
                <div className="uf1 main-info">
                  <div className="main-info-title">{item.courseName}</div>
                  <div className="main-info-subtitle line-hide-2 ">
                    <span>{item.detail}</span>
                  </div>
                  <div className="main-info-detail">
                    <span className="color-warning">
                      {item.price ? "￥" + item.price : "免费课"}
                    </span>
                    <span>
                      <span>老师：</span>
                      <span className="color-green">{item.courseTeach}</span>
                    </span>
                  </div>
                </div>
                <div className="join-study">
                  <Button
                    type="primary"
                    shape="round"
                    style={{ background: "#71B07B" }}
                    onClick={() => {
                      navigate(`/course/play/${item.courseId}/${0}/${0}`);
                    }}
                  >
                    进入学习
                  </Button>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <Empty
          image={require("@ass/base/empty-1.png")}
          txt={"没有任何课程，可以先去找找课程哦"}
        />
      )}
    </div>
  );
};

export default myCourse;
