import React, { useState } from "react";
import "./questionBank.css";
import { Button, Empty, Table } from "antd";
import search from "@ass/OJ/search.svg";
import { useQuery } from "react-query";
import request from "@/service/request";
import { useNavigate } from "react-router-dom";

const { Column } = Table;

const data = [
  {
    key: "1",
    firstName: "文件名",
    lastName: "Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    firstName: "所有者",
    lastName: "Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    firstName: "提交时",
    lastName: "Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
const QuestionBank = () => {
  const [questiontypeListIndex, setQuestiontypeListIndex] = useState(0);
  const [questionbankListIndex, setQuestionbankListIndex] = useState(0);
  const [topicListIndex, setTopicListIndex] = useState(0);
  const navigate = useNavigate();

  // 查询题库类别
  const questiontypeList = useQuery(
    ["/mmt/questiontype/list", questiontypeListIndex],
    async () => {
      const res = await request.get("/mmt/questiontype/list", {
        pageNum: 1,
        pageSize: 100,
      });
      return res.rows;
    }
  );

  // 查询题库下的题组
  const questionbankList = useQuery(
    [
      `/mmt/questionbank/list/${questiontypeListIndex}/${questionbankListIndex}`,
      questiontypeListIndex,
      questionbankListIndex,
    ],
    async () => {
      const res = await request.get("/mmt/questionbank/list", {
        pageNum: 1,
        pageSize: 100,
        typeId: questiontypeList.data[questiontypeListIndex]?.id, // 类别id
      });
      return res.rows;
    },
    {
      enabled: questiontypeList.isSuccess,
    }
  );

  // 获取题目列表
  const topicList = useQuery(
    [
      `/mmt/question/list/${questiontypeListIndex}/${questionbankListIndex}`,
      questiontypeListIndex,
      questionbankListIndex,
    ],
    async () => {
      const res = await request.get("/mmt/question/list", {
        pageNum: 1,
        pageSize: 100,
        bankId: questionbankList.data[questionbankListIndex].id, // 题组id
      });
      return res.rows;
    },
    {
      enabled: questionbankList.isSuccess,
    }
  );

  return (
    <div className="content">
      <div className="content-left">
        <div className="left-txt">题库类别</div>
        <div className="ti-list">
          {questiontypeList.data?.map((item, index) => {
            return (
              <Button
                block
                className={
                  questiontypeListIndex === index
                    ? "list-active"
                    : "ti-list-item"
                }
                key={index}
                onClick={() => {
                  setQuestiontypeListIndex(index);
                }}
              >
                {item.name}
              </Button>
            );
          })}
          {/* <Button className="list">C++课程 </Button>
          <Button className="list">C++课程 </Button> */}
        </div>
      </div>
      <div className="content-auto">
        <div className="auto-txt">
          {questiontypeList.data &&
            questiontypeList.data[questiontypeListIndex]?.name}
        </div>
        <div className="course-list">
          <div className="c-listT">
            <div>编号</div>
            <div>名称</div>
          </div>
          {questionbankList.data?.length ? (
            questionbankList.data?.map((item, index) => {
              return (
                <Button
                  className={
                    index === questionbankListIndex ? "c-listActive" : "c-list"
                  }
                  key={index}
                  onClick={() => {
                    setQuestionbankListIndex(index);
                  }}
                >
                  <div>{index + 1}</div>
                  <div>{item.name}</div>
                </Button>
              );
            })
          ) : (
            <Empty description="暂无数据" />
          )}

          {/* <Button className="c-list">
            <div>1</div>
            <div>变量和数据类型</div>
          </Button> */}
        </div>
      </div>
      <div className="content-right">
        {topicList.data?.length ? (
          <div className="top-ipt">
            <img src={search} />
            <input type="text" placeholder="请输入题目名称" />
          </div>
        ) : null}
        <Table
          dataSource={topicList.data || []}
          rowKey="id"
          pagination={{
            defaultCurrent: 1,
            // total: 50,
            hideOnSinglePage: false,
            position: ["topRight"],
          }}
          onRow={(record) => {
            return {
              onClick: (event) => {
                navigate(`/questionDetail?id=${record.id}`);
              }, // 点击行
              onDoubleClick: (event) => {},
              onContextMenu: (event) => {},
              onMouseEnter: (event) => {}, // 鼠标移入行
              onMouseLeave: (event) => {},
            };
          }}
        >
          <Column
            title="编号"
            width={200}
            render={(text, record, index) => {
              return index + 1;
            }}
          />
          <Column title="名称" dataIndex="name" key="name" width={200} />
          <Column title="标签" dataIndex="age" key="age" width={200} />
          <Column title="提交数" dataIndex="age" key="age" width={200} />
          <Column title="通过率" dataIndex="age" key="age" width={200} />
        </Table>
      </div>
    </div>
  );
};

export default QuestionBank;
