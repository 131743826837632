import React, { useState, useRef } from "react";
import "./index.css";
import { Head } from "@components";
import search from "@ass/information/search.png";
import { Table, Button, message } from "antd";
import request from "@/service/request";
import folder from "@ass/information/folder.png";

import { useQuery } from "react-query";
const { Column, ColumnGroup } = Table;

const informationIndex = () => {
  const inputRef = useRef(null);

  const [allData, setAllData] = useState([]);

  const parentIdRef = useRef(["root"]);
  const inputVal = useRef(null);
  const delItemType = useRef(""); // 判断当前删除的是文件夹还是文件

  // 查询文件夹
  const query = useQuery(
    ["/mmt/profile/list", "/mmt/attach/list"],
    async () => {
      const profileId = parentIdRef.current[parentIdRef.current.length - 1];
      const [res, res1] = await Promise.all([
        request.get("/mmt/profile/list", {
          // 查询文件夹
          pageNum: 1,
          pageSize: 10,
          parentId: profileId,
          name: inputVal.current,
        }),
        request.get("/mmt/attach/list", {
          // 查询文件
          pageNum: 1,
          pageSize: 10,
          profileId: profileId,
          name: inputVal.current,
        }),
      ]);
      let list = [];
      if (res.rows?.length) {
        list = res.rows.map((item) => {
          item.fileType = "folder";
          return item;
        });
      }
      if (res1.rows?.length) {
        list = [...list, ...res1.rows];
      }
      return list;
    }
  );

  return (
    <div className="cont">
      <Head></Head>
      <div className="source-box">
        <div className="udr uac tab-head">
          {/* <div className="source-put">
            <img src={search} />
            <input
              ref={inputRef}
              type="text"
              className="put"
              onKeyDown={(e) => {
                console.log(e);
                if (e.key === "Enter") {
                  query.refetch();
                }
              }}
              onInput={(e) => {
                inputVal.current = e.target.value;
              }}
            />
          </div> */}
          <Button
            onClick={() => {
              if (parentIdRef.current.length <= 1) {
                message.info("当前已经是最顶层");
                return;
              }
              parentIdRef.current.splice(-1);
              query.refetch();
            }}
          >
            返回上级
          </Button>
        </div>

        <Table
          // className="source-table"
          dataSource={query.data || []}
          loading={query.isLoading}
          onRow={(record) => {
            return {
              onClick: (event) => {
                if (record.suffix) {
                  if (!record.path) {
                    message.error("文件路径出错，请检查");
                    return;
                  }
                  window.open(record.path);
                  return;
                }

                parentIdRef.current.push(record.id);
                query.refetch();
              }, // 点击行
              onDoubleClick: (event) => {},
              onContextMenu: (event) => {},
              onMouseEnter: (event) => {}, // 鼠标移入行
              onMouseLeave: (event) => {},
            };
          }}
          rowKey="id"
        >
          <Column
            title="文件名称"
            dataIndex="name"
            key="name"
            render={(value, record) => {
              return (
                <div className="udr uac">
                  {record.fileType === "folder" ? (
                    <img src={folder} style={{ width: 25 }} />
                  ) : (
                    <svg
                      className="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                    >
                      <path
                        d="M106.666667 170.666667A106.666667 106.666667 0 0 1 213.333333 64h426.666667a106.666667 106.666667 0 0 1 104.533333 85.333333H810.666667A106.666667 106.666667 0 0 1 917.333333 256v469.333333a106.666667 106.666667 0 0 1-106.666666 106.666667h-85.333334c-19.456 25.898667-50.432 42.666667-85.333333 42.666667H213.333333A106.666667 106.666667 0 0 1 106.666667 768V170.666667z m637.866666 618.666666H810.666667a64 64 0 0 0 64-64V256A64 64 0 0 0 810.666667 192h-64V768c0 7.296-0.725333 14.421333-2.133334 21.333333zM213.333333 106.666667A64 64 0 0 0 149.333333 170.666667v597.333333A64 64 0 0 0 213.333333 832h426.666667a64 64 0 0 0 64-64V170.666667A64 64 0 0 0 640 106.666667H213.333333zM234.666667 298.666667a21.333333 21.333333 0 0 1 21.333333-21.333334h170.666667a21.333333 21.333333 0 0 1 0 42.666667H256a21.333333 21.333333 0 0 1-21.333333-21.333333zM256 405.333333a21.333333 21.333333 0 0 0 0 42.666667h128a21.333333 21.333333 0 0 0 0-42.666667H256zM234.666667 554.666667a21.333333 21.333333 0 0 1 21.333333-21.333334h192a21.333333 21.333333 0 0 1 0 42.666667H256a21.333333 21.333333 0 0 1-21.333333-21.333333z"
                        fill="#333333"
                      ></path>
                    </svg>
                  )}
                  <span style={{ marginLeft: 5 }}>{record.name}</span>
                </div>
              );
            }}
          />
          <Column
            title="所有者"
            dataIndex="createdBy"
            key="createdBy"
            width={200}
          />
          <Column
            title="提交时间"
            dataIndex="createdTime"
            key="createdTime"
            width={200}
          />
        </Table>
      </div>
    </div>
  );
};

export default informationIndex;
