import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Modal, message } from "antd";

import request from "@/service/request";
import "./CreateClass.css";
import { useMutation } from "react-query";
const CreateClass = (props, ref) => {
  const [open, setOpen] = useState(false);
  const delId = useRef(null);

  const openHandel = (id) => {
    setOpen(true);
    delId.current = id;
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));
  const closeHandel = () => {
    setOpen(false);
    delId.current = null;
  };
  const delClass = useMutation(async () => {
    if (!delId.current) {
      message.success("操作失败！");
      return;
    }
    const res = await request.post("mmt/classes/delete/" + delId.current);
    message.success("操作成功！");
    closeHandel();
    props.query.refetch();
    return res.data;
  });
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="te-create-modal"
      closeIcon={null}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={388}
    >
      <div className="del-title">是否删除班级?</div>
      <div className="btn-gulp">
        <div className="te-btn confirm" onClick={delClass.mutate}>
          确认
        </div>
        <div className="te-btn cancel" onClick={closeHandel}>
          取消
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(CreateClass);
