/* eslint-disable react/prop-types */
import React from "react";
import logo from "@ass/logo.png";
import "./index.css";
import { useNavigate } from "react-router-dom";

const Logo = ({ icon = logo, color = "#176a62", size = 60 }) => {
  const navigate = useNavigate();
  return (
    <div
      className="logo-box"
      onClick={() => {
        navigate("/");
      }}
    >
      <img
        className="logo"
        src={icon}
        alt="logo"
        style={{
          width: size,
          height: size,
        }}
      />
    </div>
  );
};
export default Logo;
