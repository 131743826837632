import React, { useEffect, useRef, useState } from "react";
import user from "@ass/base/user.png";
import pic from "@ass/index/1.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import ClassNumber from "./components/ClassNumber";
import bg from "@ass/classPage/classimg.png";
import styles from "../courseClass/courseClass.module.css";
import { useMutation, useQuery } from "react-query";
import request from "@/service/request";
import { Empty, message } from "antd";
import { useClassContext } from "@/pages/teacher/myClass/myClass";
import { OJURL } from "@/service/config";
import { getToken } from "@/utils";

const courseManage = () => {
  const location = useLocation();
  const [studentIndex, setStudentIndex] = useState(0);
  const AddStudentRef = useRef(null);
  const navigate = useNavigate();
  const { id, bh } = useClassContext();

  useEffect(() => {
    if (location.pathname === "/teacher/index/courseManage") {
      currentClassCourse.refetch();
    }
    // 更新上一次的位置信息为当前位置信息
  }, [location]);

  // 查询班级课程
  const currentClassCourse = useQuery(
    ["/mmt/classescourse/list", id],
    async () => {
      const res = await request.get("/mmt/classescourse/list", {
        pageNum: 1,
        pageSize: 50,
        classesId: id,
      });
      return res.rows;
    },
    {
      enabled: Boolean(id),
    }
  );

  // 查询班级学生   (已同意的)
  const getStudentList = useQuery(
    ["/mmt/mineclasses/list/1", id],
    async () => {
      const res = await request.get("/mmt/mineclasses/list", {
        pageNum: 1,
        pageSize: 50,
        classesId: id,
        approved: 1,
      });
      return res.rows;
    },
    {
      cacheTime: 0, // 过期时间
      enabled: Boolean(id),
    }
  );

  // 查询班级学生   (未同意的)
  const getStudentApply = useQuery(
    ["/mmt/mineclasses/list/0", id],
    async () => {
      const res = await request.get("/mmt/mineclasses/list", {
        pageNum: 1,
        pageSize: 50,
        classesId: id,
        approved: 0,
      });
      return res.rows;
    },
    {
      enabled: Boolean(id),
    }
  );

  // 老师同意或者拒绝进来
  const examine = useMutation(async (data) => {
    const res = await request.post("/mmt/mineclasses/edit", data);
    message.success("操作成功！");
    getStudentApply.refetch();
    getStudentList.refetch();
    return res.data;
  });

  return (
    <div className="udr tea-container">
      <div className="udc uf1 system-sub">
        <div className="udr uac ujb student-search">
          <div className="udr uac">
            <span className="people-num">
              {getStudentList.data?.length || 0}
            </span>
            <span>人</span>
          </div>
          <div className="udr uac search-input">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                stroke="#00648E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.0982 24.0611L19.2393 19.2021"
                stroke="#00648E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input placeholder="请输入同学名称" />
          </div>
          <svg
            onClick={() => {
              AddStudentRef.current.open(bh);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="36"
            viewBox="0 0 37 36"
            fill="none"
          >
            <path
              d="M18.4521 6.09277L18.4521 29.9219"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.53711 18.0078H30.3663"
              stroke="#00648E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {getStudentList.data?.length ? (
          <ul className="uf1 student-ul">
            {getStudentList.data?.map((item, index) => {
              return (
                <li
                  className={`udr uac ${
                    studentIndex === index ? "active" : null
                  }`}
                  key={index}
                >
                  <img src={user} />
                  <div>{item.sysUser.userName}</div>
                  <span
                    className="te-btn"
                    onClick={() => {
                      // OJ_URL;
                      window.open(
                        OJURL +
                          `/user-home?token=${getToken()}&username=${
                            item.sysUser.userName
                          }`
                      );
                      console.log(item, 888);
                    }}
                  >
                    查看
                  </span>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={"uf1 udc ujc "}>
            <Empty />
          </div>
        )}
      </div>
      <div className="uf1 udc system-main">
        <div className="udc apply-box">
          <div className="apply-title">
            <span className="m-title">系统通知</span>
            <span className="m-notice-num">
              {getStudentApply.data?.length || 0}
            </span>
          </div>
          {getStudentApply.data?.length ? (
            <ul className="apply-ul">
              {getStudentApply.data?.map((item, index) => {
                return (
                  <li className="udr uac ujb" key={index}>
                    <img src={user} className="sq-pic" />
                    <span className="sq-name">{item.sysUser.userName}</span>
                    <span className="sq-txt line-hide">请求加入...</span>
                    <div>
                      <span
                        className="te-btn"
                        onClick={() => {
                          item.approved = "1";
                          examine.mutate({ ...item });
                        }}
                      >
                        同意
                      </span>
                      <span
                        className="te-btn te-btn-gray"
                        onClick={() => {
                          item.approved = "2";
                          examine.mutate({ ...item });
                        }}
                      >
                        拒绝
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={"uf1 udc ujc "}>
              <Empty />
            </div>
          )}
        </div>
        <div className="uf1 udc apply-box te-course-list">
          <div className="udr uac ujb apply-title">
            <div className="udr uac search-input">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.0982 24.0611L19.2393 19.2021"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input placeholder="请输入同学名称" />
            </div>
            <div
              className="udr uac add-course-btn curp"
              onClick={() => {
                navigate("/teacher/index/addCourse");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M13.9199 6.53223V22.1701"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.10059 14.3516H21.7385"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>课程管理</span>
            </div>
          </div>
          {currentClassCourse.data?.length ? (
            <ul className="apply-ul">
              {currentClassCourse.data.map((item, index) => {
                return (
                  <li className="udr uac ujb" key={index}>
                    <img
                      src={
                        item.mmtCourse.coverPath ? item.mmtCourse.coverPath : bg
                      }
                      className={styles.courseBg}
                    />
                    <div className={styles.courseInfo}>
                      <span className="line-hide-2">{item.mmtCourse.name}</span>
                      <span className={styles.price}>
                        {item.mmtCourse.price > 0
                          ? "¥" + item.mmtCourse.price
                          : "免费"}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        sessionStorage.setItem(
                          "courseItem",
                          JSON.stringify(item)
                        );
                        navigate(
                          `/teacher/index/addStudent/${item.mmtCourse.id}`
                        );
                      }}
                    >
                      {item.mmtCourse.price > 0 ? (
                        <span className="te-btn">添加学生</span>
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={"uf1 udc ujc "}>
              <Empty />
            </div>
          )}
        </div>
      </div>
      <ClassNumber ref={AddStudentRef} />
    </div>
  );
};

export default courseManage;
