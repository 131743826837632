import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import my_left from "@ass/teacher/left.png";
import my_right from "@ass/teacher/right.png";
import user from "@ass/base/user.png";
import { PayWindow } from "@components";

import styles from "./order.module.css";
import bg from "@ass/classPage/classimg.png";
import { Empty, Modal } from "antd";

import logo from "@ass/logo.png";

import { useNavigate, useLocation } from "react-router-dom";

const Teacher = () => {
  const navigate = useNavigate();
  const [studentList, setStudentList] = useState([]);
  const [courseInfo, setCourseInfo] = useState({});
  const [showWindow, setShowWindow] = useState(false);

  useEffect(() => {
    let studentlist = sessionStorage.getItem("payStudent");
    if (studentlist) {
      setStudentList(JSON.parse(studentlist));
    }
    let courseItem = sessionStorage.getItem("courseItem");
    if (courseItem) {
      setCourseInfo(JSON.parse(courseItem));
      console.log(JSON.parse(courseItem).mmtCourse.id);
    }
  }, []);

  const reduxStudent = (item, index) => {
    const list = [...studentList];
    list.splice(index, 1);
    setStudentList(list);
  };

  const studentIds = useMemo(() => {
    const ids = studentList.map((item) => item.userId);
    return ids;
  }, [studentList]);

  const getToday = useMemo(() => {
    let currentDate = new Date();
    let year = currentDate.getFullYear(); // 获取当前年份
    let month = currentDate.getMonth() + 1; // 获取当前月份，注意月份从0开始，所以要加1
    let day = currentDate.getDate(); // 获取当前日期
    return `${year}-${month}-${day}`;
  }, []);

  const toastMoery = useMemo(() => {
    if (studentList.length) {
      return courseInfo.mmtCourse?.price * studentList.length;
    }
    return 0;
  }, [studentList, courseInfo]);

  return (
    <div className={"page-container  teacher-container"}>
      <div className="my-info">
        <div
          className="udr uac ujb left-box"
          style={{ backgroundImage: `url(${my_left})` }}
        >
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            className="logo"
          />
          <img src={user} className="my-user-pic" />
        </div>
        <div
          className="udr uac right-box"
          style={{ backgroundImage: `url(${my_right})` }}
        >
          <div className="uf1 udc username ">
            <div className="udr uac">
              <span className="nikename">购买课程</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"udr mine-main " + styles.orderMain}>
        <div className={"udc tea-container " + styles.leftBox}>
          <div className={"udr uac ujb " + styles.studentTitle}>
            <span>购买人数</span>
            <div>
              <span className={styles.peoNum}>{studentList.length}</span>
              <span className={styles.peoUnit}>人</span>
            </div>
          </div>
          {studentList?.length ? (
            <ul className={"student-ul " + styles.studentList}>
              {studentList.map((item, index) => {
                return (
                  <li className={`udr uac`} key={index}>
                    <img src={user} />
                    <div>{item.userName}</div>
                    <span
                      className="te-btn"
                      onClick={() => {
                        reduxStudent(item, index);
                      }}
                    >
                      取消
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={"uf1 udc ujc "}>
              <Empty />
            </div>
          )}
        </div>
        <div className={styles.orderInfo}>
          <div className={"udr uac " + styles.goback}>
            <div
              className="udr uac curp"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M17.3906 20.4453L10.6887 13.7434L17.3906 7.04141"
                  stroke="#666767"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>返回班级</span>
            </div>
          </div>
          <div className={"udr " + styles.clouseInfo}>
            <img src={bg} className={styles.courseBg} />
            <div className={"udc " + styles.courseTxt}>
              <div className="udc ">
                <span className={styles.titName}>
                  {courseInfo.mmtCourse?.name}
                </span>
                <span className={styles.subName}>
                  {courseInfo.mmtCourse?.detail}
                </span>
              </div>

              <span>￥{courseInfo.mmtCourse?.price}</span>
            </div>
            <div className={"udr uac ujc " + styles.ordernumber}>
              x{studentList.length}
            </div>
          </div>
          <div className={styles.line1}></div>
          <div className={styles.table}>
            <ul>
              <li className={styles.tHead}>
                <span>购课时间</span>
                <span>课程名称</span>
                <span>购买人</span>
                <span>购买价格</span>
                <span>购买数量</span>
              </li>
              {studentList.map((item, index) => {
                return (
                  <li key={index}>
                    <span>{getToday}</span>
                    <span>{courseInfo.mmtCourse?.name}</span>
                    <span>{item.userName}</span>
                    <span>{courseInfo.mmtCourse?.price}</span>
                    <span>x1</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.toastInfo}>
            <div className={styles.toast1}>
              已选择<span>1</span>件商品
            </div>
            <div className={styles.toast1}>
              购买人数<span>{studentList.length}</span>人
            </div>
            <div className={styles.toast1 + " " + styles.toast2}>
              总计金额：
              <span>￥{toastMoery}</span>
            </div>
            <div
              className={styles.settlement}
              onClick={() => {
                setShowWindow(true);
              }}
            >
              去结算
            </div>
          </div>
        </div>
      </div>
      <PayWindow
        state={showWindow}
        businessId={courseInfo?.mmtCourse?.id}
        money={toastMoery.toFixed(2)}
        userIds={studentIds}
        openFunction={() => {
          setShowWindow(false);
        }}
        callback={() => {
          Modal.success({
            title: "购买成功",
            content: "即将返回上一页",
            onOk: () => {
              navigate(-1);
            },
          });
        }}
      />
    </div>
  );
};

export default Teacher;
