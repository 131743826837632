import React from "react";
import './index.css'
export default function UserAgreement() {
  return (
    <div className="markdown-typed-wrap typo" style={{
        boxSizing: 'border-box',
        color: '#1f1f1f',
        lineHeight: 1,
        overflowX: 'auto',
        verticalAlign: 'top',
        width: '100%',
        wordBreak: 'break-all'
    }}>
      <p>
        <strong>萌萌码网站用户协议</strong>
      </p>
      <p>
        <strong>一、用户资格</strong>
      </p>
      <p>
        本协议适用于所有访问和/或使用萌萌码网站（以下简称“本网站”）的用户。用户在注册和使用本网站之前，应确保自己已满足所有适用的年龄、身份和法律要求，并具有完全民事行为能力。
      </p>
      <p>
        <strong>二、账号与密码</strong>
      </p>
      <p>
        用户在注册时须提供真实、准确、完整的信息，并保持这些信息的实时有效性。用户应妥善保管自己的账号和密码，对于因账号和密码泄露产生的任何损失，本网站不承担责任。
      </p>
      <p>
        <strong>三、内容发布</strong>
      </p>
      <p>
        用户在本网站发布的信息应当符合法律法规、公序良俗和道德规范。任何侵犯他人权益、散布虚假信息、侮辱或诽谤他人、侵犯知识产权的内容，将被删除或采取其他必要措施，且用户须承担相应的法律责任。
      </p>
      <p>
        <strong>四、版权与知识产权</strong>
      </p>
      <p>
        本网站上所有内容，包括但不限于文字、图片、音频、视频等，其版权和知识产权归属于本网站或原作者。除非获得明确的许可，用户不得以任何形式复制、传播、展示或利用这些内容。
      </p>
      <p>
        <strong>五、隐私保护</strong>
      </p>
      <p>
        本网站尊重用户的隐私权，将严格遵守相关法律法规，对用户的个人信息进行保护。未经用户同意，本网站不会泄露或向第三方提供用户的个人信息。
      </p>
      <p>
        <strong>六、交易与支付</strong>
      </p>
      <p>
        如本网站提供购买服务，用户应按照相关指引进行操作。对于购买过程中产生的任何问题，本网站将尽力协助解决，但最终责任由用户自行承担。
      </p>
      <p>
        <strong>七、免责条款</strong>
      </p>
      <p>
        对于因不可抗力、病毒或黑客攻击、系统维护等原因造成的服务中断或数据丢失，本网站不承担任何责任。对于用户在本网站上发布的任何内容，本网站不保证其真实性、准确性和完整性。
      </p>
      <p>
        <strong>八、法律适用与争议解决</strong>
      </p>
      <p>
        本协议的签订、履行和解释，以及与本协议有关的争议，均适用中华人民共和国法律。如发生争议，双方应首先友好协商解决；协商不成的，任何一方均有权向本网站所在地的人民法院提起诉讼。
      </p>
      <p>
        <strong>九、修改与终止</strong>
      </p>
      <p>
        本网站有权在不事先通知的情况下修改本协议。用户应定期查阅本协议，以确保自己了解最新的条款。如用户继续使用本网站，即表示同意修改后的协议。如发现用户违反本协议的任何条款，本网站有权立即终止用户的服务。
      </p>
      <p>
        <strong>十、违约处理</strong>
      </p>
      <p>
        如用户违反本协议的任何条款，本网站有权采取包括但不限于警告、限制或终止用户账号、删除用户发布的内容等措施。对于因用户违约行为产生的任何损失，本网站有权要求用户赔偿。
      </p>
    </div>
  );
}
