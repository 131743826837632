/* eslint-disable react/react-in-jsx-scope */
import "./App.css";
import Router from "./router/index";
import { QueryClient, QueryClientProvider } from "react-query";
import zhCN from "antd/es/locale/zh_CN";

import { ConfigProvider } from "antd";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
function App() {
  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: "#00b96b" } }}
      locale={zhCN}
    >
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
