import axios from "axios";
import qs from "qs";
import { notification, Modal, message } from "antd";

import { BASE_URL, TIMEOUT } from "./config";
import {
  getToken,
  clearLocalDatas,
  getLocalUser,
  removeToken,
  removeUser,
  USER_INFO,
  TOKEN,
  MENU,
} from "@/utils";

// 错误信息
const codeMessage = {
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": " application/json;charset=UTF-8",
  },
});

instance.interceptors.request.use(
  (config) => {
    // 1.发送网络请求时候,在界面的中间位置显示loading组件

    // 2.某一些请求用户必须携带TOKEN,如果没有携带，那么直接跳转到登陆页面
    // 在发送请求之前做些什么
    let token = getToken();
    if (token) {
      config.headers["authorization"] = token;
    }
    // 3.params/data序列化的操作,qs.stringfly
    return config;
  },
  (err) => {}
);

instance.interceptors.response.use(
  function (response) {
    if (response.data) {
      let { msg, code } = response.data;
      if (code === 401) {
        Modal.warning({
          title: "警告！",
          content: "当前无法访问！",
          onOk: () => {
            removeToken();
            removeUser();
            window.location.href = "/";
          },
        });
        return;
      }
      if (codeMessage[code]) {
        message.error(msg);
        return Promise.reject(response.data);
      }
    }
    return response && response.data;
  },
  function (error) {
    const { response } = error;
    if (response && response.status) {
      const errorText = codeMessage[response.status] || response.statusText;
      const { status, config } = response;
      notification.error({
        message: `请求错误 ${status}: ${config.url}`,
        description: errorText,
      });
      // if (response.status === 401 || response.status === 403) {
      //   clearLocalDatas([USER_INFO, TOKEN, MENU]);
      //   store.dispatch(clearUser());
      //   setTimeout(() => {
      //     window.location.href = "/login";
      //   }, 1000);
      // }
    } else if (!response) {
      notification.error({
        description: "客户端异常或网络问题，请清除缓存！",
        message: "状态异常",
      });
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
const rewriteGet = instance.get;
instance.get = function (url, data, ...any) {
  let query = qs.stringify(data, { addQueryPrefix: true });
  return rewriteGet(url + query, ...any);
};
export default instance;
