import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Modal, message } from "antd";
import request from "@/service/request";
import styles from "./EditModal.module.css";
import { useMutation } from "react-query";

const CreateClass = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [formObj, setFormObj] = useState({});

  const openHandel = () => {
    setOpen(true);
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));
  const closeHandel = () => {
    setOpen(false);
  };
  const editUserInfo = useMutation(async () => {
    if (editUserInfo.isLoading) {
      return;
    }
    const res = await request.put("/system/user", {
      ...props.data,
      ...formObj,
    });
    message.success("修改成功！");
    closeHandel();
    props.query?.refetch();
    console.log(222);
    return res.data;
  });

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="edit-userinfo-modal"
      closeIcon={null}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={'30%'}
    >
      <div className={styles.title}>修改个人信息</div>
      <ul className={styles.infolist}>
        <li className="udr uac ujb">
          <label>用户名</label>
          <input
            placeholder="用户名"
            defaultValue={props.data?.nickName}
            onChange={(e) => {
              setFormObj({ ...formObj, nickName: e.target.value });
            }}
          />
        </li>
        <li className="udr uac ujb">
          <label>手机号</label>
          <input
            placeholder="手机号"
            defaultValue={props.data?.phonenumber}
            type="phone"
            maxLength={11}
            onChange={(e) => {
              setFormObj({ ...formObj, phonenumber: e.target.value });
            }}
          />
        </li>
        <li className="udr uac ujb">
          <label>邮箱</label>
          <input
            placeholder="邮箱"
            type="email"
            defaultValue={props.data?.email}
            onChange={(e) => {
              setFormObj({ ...formObj, email: e.target.value });
            }}
          />
        </li>
        <li className="udr uac ujb">
          <label>个性签名</label>
          <input
            placeholder="这个人很懒什么也没留下~"
            defaultValue={props.data?.remark}
            onChange={(e) => {
              setFormObj({ ...formObj, remark: e.target.value });
            }}
          />
        </li>
      </ul>
      <div className={"udr uac ujc " + styles.btnGulp}>
        <div className={styles.confirm} onClick={editUserInfo.mutate}>
          确认
        </div>
        <div className={styles.cancel} onClick={closeHandel}>
          取消
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(CreateClass);
