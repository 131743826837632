import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Modal, message } from "antd";

import request from "@/service/request";
import "./CreateClass.css";
import { useMutation } from "react-query";
const CreateClass = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const openHandel = () => {
    setOpen(true);
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));
  const closeHandel = () => {
    setOpen(false);
    setName("");
  };
  const addClass = useMutation(async () => {
    if (!name) {
      message.error("请输入班级名称");
      return;
    }
    const res = await request.post("/mmt/classes/add", {
      belongUserid: props.userinfo.userId,
      name: name,
    });
    message.success("创建成功！");
    closeHandel();
    setName("");
    props.query.refetch();
    return res.data;
  });
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="te-create-modal"
      closeIcon={<img src={require("@ass/base/close.png")} className="close" />}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={388}
    >
      <div className="title">创建班级名称</div>
      <div className="create-class-input">
        <div className="create-class-input-box">
          <input
            placeholder="请输入班级名称"
            value={name}
            onInput={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="btn-gulp">
        <div className="te-btn confirm" onClick={addClass.mutate}>
          确认
        </div>
        <div className="te-btn cancel" onClick={closeHandel}>
          取消
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(CreateClass);
