import React, { useRef, useState } from "react";
import user from "@ass/base/user.png";
import edit from "@ass/home/edit.svg";
import del from "@ass/home/del.svg";
import plus from "@ass/home/plus.svg";
import up from "@ass/home/up.svg";
import dl from "@ass/home/dl.svg";
import pen from "@ass/home/pen.svg";
import rl from "@ass/home/rl.svg";
import numOne from '@ass/home/numOne.svg'
import { useNavigate } from "react-router-dom";
import { Progress,Modal,Button } from "antd";
import './match.css'
const courseManage = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const goStudentDetail = () => {
    navigate("/teacher/index/studentDetail");
  }; 

  return (
    <div className="udr match-container">
      <div className="udr summary">
        <div className="udr uac ujb uf1 progress-box">
          <div className="progress-info">
            <span className="txt">考试满分</span>
            <span className="val val-1">15人</span>
          </div>
          <Progress
            size={89}
            type="circle"
            percent={15}
            strokeWidth={10}
            strokeColor="#7A92CE"
            trailColor="#ECECEC"
          />
        </div>
        <div className="udr uac ujb uf1 progress-box">
          <div className="progress-info">
            <span className="txt">考试优秀</span>
            <span className="val val-2">30人</span>
          </div>
          <Progress
            size={89}
            type="circle"
            percent={30}
            strokeWidth={10}
            strokeColor="#458B86"
            trailColor="#ECECEC"
          />
        </div>
        <div className="udr uac ujb uf1 progress-box">
          <div className="progress-info">
            <span className="txt">考试及格</span>
            <span className="val val-3">50人</span>
          </div>
          <Progress
            size={89}
            type="circle"
            percent={50}
            strokeWidth={10}
            strokeColor="#F1AF13"
            trailColor="#ECECEC"
          />
        </div>
        <div className="udr uac ujb uf1 progress-box">
          <div className="progress-info">
            <span className="txt">不及格</span>
            <span className="val val-4">5人</span>
          </div>
          <Progress
            size={89}
            type="circle"
            percent={50}
            strokeWidth={10}
            strokeColor="#CE4C6C"
            trailColor="#ECECEC"
          />
        </div>
        <div className='del-game'>
          删 除 比 赛
        </div>
      </div>
      <div className='but-box'>
        <div className="box-one">
          <div className="time-box">
            <div className="title">
              <div>比赛日期</div>
            </div>
            <div className = "select-time">
                <div>2023-11-06-15:00 -- 2023-11-12-06:00</div>
              <img style={{marginLeft:'35px'}} src={rl} alt="" />
            </div>
          </div>
          <div className="course-list">
            <div style={{display:"flex",justifyContent:'space-between'}}>
              <div className="txt">比赛班级</div>
              <img src={plus} alt="" />
            </div>

            <div className="course-item">
              <div className="course-left">C++学习一班</div>
              <div className="course-right">
                <img src={del} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="box-two">
          <div className="title">
            <div>比赛题目</div>
            <img src={edit} alt="" />
          </div>
          <div className="timu">
            <div className="titx">
              <div className='titx-left'>
                变量和数据类型 / 20
                <img src={up} alt="" />
              </div>
              <div className='titx-right'>
                <img src={plus} alt="" />
              </div>
            </div>
              <div className="list">
                <div className="list-one">1.C++选择题</div>
                <div className="list-two">正确率：75%</div>
                <img src={pen} alt="" />
                <img style={{marginLeft:'13px'}} src={dl} alt="" />
              </div>
          </div>
        </div>
        <div className="box-three">
          <div className="paimimg">比赛排名</div>
          <div className="minepaiming">
            个人排名
          </div>
          <div className="user-list">
            <div className="user-infor">
              <div className='num'>1</div>
              <img src={user} style={{width: '40.68px',height:'44.68px',marginLeft:'7px'}} alt="" />
              <div className="user">
                <div  className='name'>
                    <div className='name-zs'>张三</div>
                   <div className='name-bj'>C++学习一班</div>
                </div>
              </div>

              <div className="fen">100分</div>
            </div>
          </div>
        </div>

      </div>


      <Modal title="Basic Modal" open={isModalOpen}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </div>
  );
};

export default courseManage;
