import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Modal, message } from "antd";

import request from "@/service/request";
import "./CreateClass.css";
import { useMutation } from "react-query";


const CreateClass = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const openHandel = () => {
    setOpen(true);
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));
  const closeHandel = () => {
    setOpen(false);
    setName("");
  };
  const addClass = useMutation(async () => {
    if (!name) {
      message.error("请输入个人ID号码");
      return;
    }
    const res = await request.post("/mmt/classes/add", {
      groupId: props.groupId,
      userId: name,
      roleName: activeIndex === 0 ? 'institution' : "teacher"
    });
    message.success("创建成功！");
    closeHandel();
    setName("");
    props.query.refetch();
    return res.data;
  });
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="ag-create-modal"
      closeIcon={null}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={388}
    >
      <div className="title">请输入个人ID号码</div>
      <div className="create-class-input">
        <div className="create-class-input-box">
          <input
            placeholder="请输入个人ID号码"
            value={name}
            onInput={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="title">请选择人员身份</div>
      <div className="create-class-input">
        <ul className="udr create-class-select-box">
          <li
            className={activeIndex === 0 ? "active" : ""}
            onClick={() => {
              setActiveIndex(0);
            }}
          >
            代理人
          </li>
          <li
            className={activeIndex === 1 ? "active" : ""}
            onClick={() => {
              setActiveIndex(1);
            }}
          >
            老师
          </li>
        </ul>
      </div>
      <div className="cre-btn-gulp">
        <div className="te-btn confirm" onClick={addClass.mutate}>
          确认
        </div>
        <div className="te-btn cancel" onClick={closeHandel}>
          取消
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(CreateClass);
