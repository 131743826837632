const DEBUG = process.env.NODE_ENV === "development";

const devBaseURL = process.env.MMTAPP_URL ?? "http://112.124.65.60:8080"; // dev 环境

// const proBaseURL ="http://81.70.172.135:8070/prod-api";
// const proBaseURL ="http://82.157.98.79:8070/prod-api";
const proBaseURL ="http://101.43.181.60:8070/prod-api";
// const proBaseURL = "http://localhost:8080";
// const proBaseURL = "http://42.193.96.88/prod-api";

export const BASE_URL = proBaseURL;

const OJ_URL = process.env.MMTOJ_URL;
export const OJURL ="http://42.193.96.88:8099";
export const OJ_ADMIN_URL ="http://42.193.96.88:8099/admin/login";

// export const ADMINURL = "http://82.157.98.79:8070/react-ant-admin/";
export const ADMINURL = "http://101.43.181.60:8070/react-ant-admin/";
// export const ADMINURL = "http://42.193.96.88:8070/react-ant-admin/";

export const TIMEOUT = 10000;
