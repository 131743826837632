/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./navs.css";
const Navs = ({ list = [], onChange, rightContent }) => {
  const [navIndex, setNavIndex] = useState(0);

  const navClick = (index) => {
    setNavIndex(index);
    onChange?.(index);
  };

  return (
    <ul className="udr uac navs-ul">
      {list.map((item, index) => {
        return (
          <li
            className={index === navIndex ? "nav-ative" : ""}
            key={index}
            onClick={() => {
              navClick(index);
            }}
          >
            {item}
          </li>
        );
      })}
      {}
      <li className="nav-right">{rightContent}</li>
    </ul>
  );
};

export default Navs;
