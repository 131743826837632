import React from "react";

import wechat from "@ass/index/wechat.png";

import { BackTop } from "@components";
import "./index.css";
import { Tooltip } from "antd";
import qrcode from "./qrcode.jpeg";

const RightFloat = ({ hideBackTop = false }) => {
  return (
    <div className="float-grop">
      <Tooltip
        placement="left"
        title={
          <img
            style={{ width: 100, height: 100, borderRadius: 5 }}
            src={qrcode}
          />
        }
      >
        <div className="wechat-box">
          <img src={wechat} className="go-top" />
          <span className="wechat-txt">相关咨询</span>
        </div>
      </Tooltip>

      {hideBackTop ? null : <BackTop />}
    </div>
  );
};
export default RightFloat;
