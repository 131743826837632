import React from "react";
import Navs from "../components/navs/navs";
import question_bg from "@ass/mine/question-bg.png";
import question_img from "@ass/course/12.png";
import { Button } from "antd";
const Competition = () => {
  return (
    <div className="course-page-containe">
      <div className="mi-nav-box">
        <Navs list={["全部", "C++", "Python"]} />
      </div>
      <ul className="mine-my-cour-ul">
        {[1, 2, 31, 11, 1, 1].map((item, index) => {
          return (
            <li className="udr uac" key={index}>
              <div
                className="uac ujc udr mine-item-bg"
                style={{ backgroundImage: `url(${question_bg})` }}
              >
                <img src={question_img} />
              </div>
              <div className="uf1 main-info">
                <div className="main-info-title">请输入课程的名称</div>
                <div className="main-info-subtitle">
                  此处输入对于课程的简单介绍，以及课程带来的学习好处
                </div>
                <div className="main-info-detail">
                  <span className="color-warning">￥499.00</span>
                  <span>
                    <span>人数</span>
                    <span className="color-green">40</span>
                  </span>
                  <span>
                    <span>学习时常</span>
                    <span className="color-green">25h</span>
                  </span>
                </div>
              </div>
              <div>
                <Button
                  // disabled={true}
                  className="comp-btn mma-btn"
                  type="primary"
                  shape="round"
                >
                  进入比赛
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Competition;
