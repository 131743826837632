export const USER_INFO = "USER_INFO";
export const TOKEN = "REACT_ADMIN_TOKEN";
export const MENU = "MENU";
export const VISIBLE = "COMPONENTS_VISIBLE";
export const LAYOUT_MODE = "LAYOUT_MODE";

function sleep(seconed) {
  return new Promise((res, rej) => {
    setTimeout(res, seconed);
  });
}
function getStorage(isLocal) {
  return isLocal ? window.localStorage : window.sessionStorage;
}
function getKey(isLocal, key) {
  return JSON.parse(getStorage(isLocal).getItem(key) || "null");
}

function setKey(isLocal, key, data) {
  getStorage(isLocal).setItem(key, JSON.stringify(data || null));
}

function getLocalUser() {
  return getKey(true, USER_INFO);
}

function saveToken(token) {
  setKey(true, TOKEN, token);
}

function getToken() {
  return getKey(true, TOKEN);
}

function getSessionUser() {
  return getKey(false, USER_INFO);
}

function saveUser(info) {
  setKey(true, USER_INFO, info);
  setKey(false, USER_INFO, info);
}

function rmKey(isLocal, key) {
  getStorage(isLocal).removeItem(key);
}

function removeUser() {
  rmKey(true, USER_INFO);
}

function removeToken() {
  rmKey(true, TOKEN);
}
function stopPropagation(e) {
  e.stopPropagation();
}

function clearLocalDatas(keys) {
  keys.forEach((key) => {
    rmKey(true, key);
    rmKey(false, key);
  });
}

//获取地址栏参数
function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

// 秒数转换为时分秒
function getTime(time) {
  let h = parseInt((time / 60 / 60) % 24);
  h = h < 10 ? "0" + h : h;
  let m = parseInt((time / 60) % 60);
  m = m < 10 ? "0" + m : m;
  let s = parseInt(time % 60);
  s = s < 10 ? "0" + s : s;
  // 作为返回值返回
  if (h <= 0) {
    return `${m}:${s}`;
  }
  if (h <= 0 && m <= 0) {
    return s;
  }
  return `${h}:${m}:${s}`;
}

function copyToClipboard(text) {
  var textarea = document.createElement("textarea");
  textarea.style.position = "fixed";
  textarea.style.opacity = 0;
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

export {
  getSessionUser,
  saveUser,
  sleep,
  getLocalUser,
  saveToken,
  getToken,
  getKey,
  setKey,
  rmKey,
  stopPropagation,
  clearLocalDatas,
  GetQueryString,
  guid,
  getTime,
  removeToken,
  removeUser,
  copyToClipboard,
};
