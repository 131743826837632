import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Head } from "@components";
import my_left from "@ass/mine/bg1.png";
import my_right from "@ass/mine/bg2.png";
import user from "@ass/base/user.png";
import nav1 from "@ass/mine/nav1.png";
import nav2 from "@ass/mine/nav2.png";
import nav3 from "@ass/mine/nav3.png";
import nav4 from "@ass/mine/nav4.png";
import nav5 from "@ass/mine/nav5.png";
import nav6 from "@ass/mine/nav6.png";
import nav_bg from "@ass/mine/nav-bg.png";
import Vector from "@ass/mine/Vector.png";
import logo from "@ass/logo.png";

import { getLocalUser } from "@/utils";

import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";

const navList = [
  {
    txt: "学校管理",
    icon: nav1,
    pathname: "/agentIndex/school",
  },
  {
    txt: "个人信息",
    icon: nav2,
    pathname: "/agentIndex/teacherSetting",
  },
];

const Index = () => {
  const [navIndex, setNavIndex] = useState(0);
  const navigate = useNavigate();
  const localtion = useLocation();

  const [userinfo, setUserinfo] = useState({});

  useEffect(() => {
    const userInfo = getLocalUser();
    setUserinfo(userInfo);

    navList.forEach((item, index) => {
      if (item.pathname === localtion.pathname) {
        setNavIndex(index);
      }
    });
  }, []);
  const routeChange = (item, index) => {
    if (item.pathname) {
      console.log(item.pathname);
      navigate(item.pathname);
    }
    setNavIndex(index);
  };

  return (
    <div className="page-container teacher-container">
      <div className="my-info">
        <div
          className="udr uac ujb left-box"
          style={{ backgroundImage: `url(${my_left})` }}
        >
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            className="logo"
          />
          <img src={user} className="my-user-pic" />
        </div>
        <div
          className="udr uac right-box"
          style={{ backgroundImage: `url(${my_right})` }}
        >
          <div className="uf1 udc username ">
            <div className="udr uac">
              <span className="nikename">{userinfo.nickName}</span>
              <span className="career">
                {userinfo.roleName === "institution" ? "代理人" : "老师"}
              </span>
            </div>
            <div>
              这位{userinfo.roleName === "institution" ? "代理人" : "老师"}
              很懒，什么也没留下~
            </div>
          </div>
          {/* <div className="uf1 udc user-integral">
            <span className="user-integral-num">{userinfo.integral}</span>
            <span className="user-integral-txt">积分</span>
          </div> */}
        </div>
      </div>
      <div className="udr mine-main">
        <ul className="my-nav-list">
          {navList.map((item, index) => {
            return (
              <li
                key={index}
                className={navIndex === index ? "active" : ""}
                onClick={() => {
                  routeChange(item, index);
                }}
              >
                <div className="mine-nav-icon">
                  <img src={item.icon} />
                </div>
                <span className="mine-nav-txt">{item.txt}</span>
                <img src={nav_bg} />
              </li>
            );
          })}
        </ul>
        <div className="uf1 udr three-route">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Index;
