/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Head, RightFloat, PayWindow, Videojs } from "@components";
import "./detail.css";
import video1 from "@ass/course/video1.png";
import rocket from "@ass/course/rocket.png";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import request from "@/service/request";
import { getTime } from "@/utils";
import { message } from "antd";

const Detail = () => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [liindex, setLiindex] = useState(0);
  const [courseIndex, setCourseIndex] = useState(0); // 章节索引
  const [subsectionIndex, setSubsectionIndex] = useState(0); //小节索引
  const [videoDuration, setVideoDuration] = useState(0);

  const [navIndex, setNavIndex] = useState(0);
  const [navList] = useState(["课程介绍", "课程目录"]);

  const detailData = useQuery([`/mmt/course/menu/${params.id}`], async () => {
    const [res] = await Promise.all([
      request.get(`/mmt/course/menu/${params.id}`),
    ]);
    const mmtCourse = res.data.mmtCourse || {};

    // let list = [];
    // res.data.chapterDTOs.forEach((item) => {
    //   list = list.concat(item.sessions);
    // });
    // const tryItem = list.filter((item) => item.isTry === "1")[0];
    // playerRef.current.pause()
    // playerRef.current.src(tryItem.videoPath);

    return {
      course: mmtCourse,
      chapterDTOs: res.data.chapterDTOs,
    };
  });

  const courseInfo = useMemo(() => {
    let info = {};

    if (!detailData.data) {
      info = {
        flag: false,
        txt: "",
      };
    }
    //免费课
    else if (!detailData.data.course.price) {
      info = {
        flag: true,
        txt: "立即学习",
      };
    }
    // 付费课，并且没有购买课程
    else if (
      detailData.data.course.price > 0 &&
      detailData.data.course.assetStatus !== "OWN"
    ) {
      info = {
        flag: false,
        txt: "立即购买",
      };
    }
    // 已经购买课程了
    else {
      info = {
        flag: true,
        txt: "立即学习",
      };
    }
    return info;
  }, [detailData.data]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // 你可以在这里处理玩家活动
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tryPlay = (item) => {
    // console.log(item.videoPath);
    playerRef.current.src(item.videoPath);
    playerRef.current.play();
  };

  const changePlay = (index) => {
    setSubsectionIndex(index);
  };
  const [showWindow, setShowWindow] = useState(false);
  return (
    <div
      className="page-container detail-container"
      style={{ paddingBottom: 0 }}
    >
      <Head />
      <div className="de-body">
        {/* 面包屑 */}
        <div className="breadcrumbs">
          <span
            className="curp"
            onClick={() => {
              navigate(-1);
            }}
          >
            精品课堂
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
          >
            <path
              d="M1.0651 0.173901C0.948263 0.0610542 0.791775 -0.00138808 0.629344 2.34192e-05C0.466912 0.00143491 0.311534 0.0665872 0.196673 0.181448C0.0818124 0.296309 0.0166599 0.451687 0.0152484 0.614119C0.0138369 0.77655 0.0762793 0.933038 0.189127 1.04988L4.13164 4.99239L0.189127 8.9349C0.129958 8.99204 0.0827626 9.0604 0.0502951 9.13599C0.0178276 9.21157 0.000738188 9.29286 2.33907e-05 9.37512C-0.000691406 9.45737 0.0149831 9.53895 0.0461322 9.61508C0.0772813 9.69122 0.123281 9.76039 0.181448 9.81855C0.239614 9.87672 0.308783 9.92272 0.384918 9.95387C0.461052 9.98502 0.542628 10.0007 0.624885 9.99998C0.707142 9.99926 0.788433 9.98217 0.864015 9.94971C0.939597 9.91724 1.00796 9.87004 1.0651 9.81087L5.44374 5.43285L5.4456 5.43037C5.56174 5.3142 5.62698 5.15666 5.62698 4.99239C5.62698 4.82812 5.56174 4.67057 5.4456 4.5544"
              fill="black"
            />
          </svg>
          <span>课程详情</span>
        </div>
        <div className="course-info">
          <div className="course-info-left">
            <div className="title">{detailData.data?.course.name}</div>
            <div className="introduce">{detailData.data?.course.detail}</div>

            <div className="udr uac duration">
              <span className="ta-1">难度：</span>
              <span className="ta-2">{detailData.data?.course.difficulty}</span>
            </div>
            <div className="price">
              {detailData.data?.course.price > 0
                ? `￥${detailData.data?.course.price.toFixed(2)}`
                : "免费"}
            </div>
            {/* <div className="btn-gulp">
            <button className="btn btn-active">概括</button>
            <button className="btn">目录</button>
            <button className="btn">评价</button>
          </div> */}
            {/* <div className="hr"></div>
          <div className="udr uac tip">
            <img src={edit} className="edit" />
            <span>你将学到</span>
          </div>
          <div className="harvest">
            {[1, 2, 3, 4, 5, 6].map((item, index) => {
              return (
                <div className="harvest-50" key={index}>
                  <span className="round" />
                  <span className="harvest-item1">基础智力开发</span>
                </div>
              );
            })}
          </div> */}
          </div>
          <div className="course-info-right">
            <div className="video-box">
              {/* <video
                ref={videoRef}
                onPlay={() => {
                  console.log("播放");
                  setVideoControls(true);
                }}
                onPause={() => {
                  setVideoControls(false);
                  console.log("暂停");
                }}
                controls={videoControls}
                src={
                  detailData.data?.chapterDTOs.length &&
                  detailData.data?.chapterDTOs[courseIndex].sessions.length &&
                  detailData.data?.chapterDTOs[courseIndex].sessions[
                    subsectionIndex
                  ]?.videoPath
                }
              /> */}
              <Videojs onReady={handlePlayerReady} />
              {isPlaying && (
                <div
                  className="udc video-info"
                  onClick={() => {
                    if (playerRef.current) {
                      console.log("设置播放");
                      playerRef.current.play();
                    }
                  }}
                >
                  <img src={video1} className="video-pic" />
                  <span className="video-txt">
                    试看本节课 {getTime(videoDuration)}
                  </span>
                </div>
              )}

              {/* <div className="udr uac video-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                onClick={() => {
                  let num = subsectionIndex - 1;
                  if (num < 0) {
                    num = subsectionList.length - 1;
                  }
                  setSubsectionIndex(num);
                  videoRef.current.pause();
                }}
              >
                <path
                  d="M9.1237 0.347803C9.35738 0.122108 9.67036 -0.00277615 9.99522 4.68383e-05C10.3201 0.00286983 10.6308 0.133174 10.8606 0.362896C11.0903 0.592617 11.2206 0.903375 11.2234 1.22824C11.2262 1.5531 11.1013 1.86608 10.8757 2.09975L2.99063 9.98478L10.8757 17.8698C10.994 17.9841 11.0884 18.1208 11.1533 18.272C11.2183 18.4231 11.2524 18.5857 11.2539 18.7502C11.2553 18.9147 11.2239 19.0779 11.1616 19.2302C11.0993 19.3824 11.0073 19.5208 10.891 19.6371C10.7747 19.7534 10.6363 19.8454 10.4841 19.9077C10.3318 19.97 10.1687 20.0014 10.0041 20C9.83962 19.9985 9.67704 19.9643 9.52588 19.8994C9.37471 19.8345 9.238 19.7401 9.1237 19.6217L0.366424 10.8657L0.362706 10.8607C0.130429 10.6284 -5.81741e-05 10.3133 -5.81741e-05 9.98478C-5.81741e-05 9.65624 0.130429 9.34115 0.362706 9.1088"
                  fill="white"
                />
              </svg>
              <span>{subsectionList[subsectionIndex]?.name}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                onClick={() => {
                  const num = (subsectionIndex + 1) % subsectionList.length;
                  setSubsectionIndex(num);
                  videoRef.current.pause();
                }}
              >
                <path
                  d="M2.38411 0.347803C2.15043 0.122108 1.83746 -0.00277615 1.51259 4.68383e-05C1.18773 0.00286983 0.876974 0.133174 0.647252 0.362896C0.417531 0.592617 0.287226 0.903375 0.284403 1.22824C0.28158 1.5531 0.406465 1.86608 0.632159 2.09975L8.51718 9.98478L0.632159 17.8698C0.513822 17.9841 0.419431 18.1208 0.354496 18.272C0.289562 18.4231 0.255383 18.5857 0.253953 18.7502C0.252523 18.9147 0.283872 19.0779 0.346171 19.2302C0.408469 19.3824 0.500468 19.5208 0.616802 19.6371C0.733135 19.7534 0.871472 19.8454 1.02374 19.9077C1.17601 19.97 1.33916 20.0014 1.50368 20C1.66819 19.9985 1.83077 19.9643 1.98194 19.8994C2.1331 19.8345 2.26982 19.7401 2.38411 19.6217L11.1414 10.8657L11.1451 10.8607C11.3774 10.6284 11.5079 10.3133 11.5079 9.98478C11.5079 9.65624 11.3774 9.34115 11.1451 9.1088"
                  fill="white"
                />
              </svg>
            </div> */}
            </div>

            <div
              className="operate"
              onClick={() => {
                console.log("courseInfo", courseInfo);
                if (!courseInfo.flag) {
                  setShowWindow(true);
                } else {
                  navigate(`/course/play/${params.id}/${0}/${0}`);
                }
              }}
            >
              {courseInfo.txt}
            </div>
            {/*<div className="operate cart">立即报名</div>*/}
          </div>
        </div>
      </div>
      <div className="de-footer">
        <div className="udr uac ujc nav-list">
          {navList.map((item, index) => {
            return (
              <div
                key={index}
                className={index === navIndex ? "active" : ""}
                onClick={() => {
                  setNavIndex(index);
                }}
              >
                {item}
              </div>
            );
          })}
          {/*<div>用户评价</div>*/}
        </div>
        {navIndex === 0 && (
          <div className="course-introduction">
            <div className="title">讲师介绍</div>
            <div className="udr uac teacher-info">
              <img src={detailData.data?.course.teachImage} />
              <div className="udc teacher-c">
                <span>{detailData.data?.course.teachName}</span>
                <span>讲师</span>
              </div>
            </div>
            <div className="sub">{detailData.data?.course.teachIntro}</div>
            <div className="course-details">课程详情</div>
            <div className="course-details-sub">
              {detailData.data?.course.detail}
            </div>
          </div>
        )}
        {navIndex === 1 && (
          <div className="course-introduction directory-box">
            <div className="udr uac directory">
              <img src={rocket} />
              <span>
                学习期次：随到随学-{detailData.data?.course.createdTime}
              </span>
            </div>
            <ul className="directory-list">
              {detailData.data?.chapterDTOs.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${index === liindex ? "active" : ""}`}
                  >
                    <div
                      className="udr uac directory-item"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (index === liindex) {
                          setLiindex(-1);
                          return;
                        }
                        setLiindex(index);
                      }}
                    >
                      <span className="name">
                        {item?.mmtCourseChapter.name}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="12"
                        viewBox="0 0 20 12"
                        fill="none"
                      >
                        <path
                          d="M0.347803 2.1302C0.122108 1.89653 -0.00277615 1.58355 4.68383e-05 1.25869C0.00286983 0.933825 0.133174 0.623068 0.362896 0.393346C0.592617 0.163625 0.903375 0.0333198 1.22824 0.0304968C1.5531 0.0276738 1.86608 0.152559 2.09975 0.378253L9.98477 8.26327L17.8698 0.378253C17.9841 0.259916 18.1208 0.165525 18.272 0.10059C18.4231 0.0356553 18.5857 0.00147638 18.7502 4.67814e-05C18.9147 -0.00138281 19.0779 0.0299661 19.2302 0.0922644C19.3824 0.154563 19.5208 0.246562 19.6371 0.362895C19.7534 0.479229 19.8454 0.617566 19.9077 0.769835C19.97 0.922104 20.0014 1.08526 20 1.24977C19.9985 1.41428 19.9643 1.57687 19.8994 1.72803C19.8345 1.87919 19.7401 2.01591 19.6217 2.1302L10.8657 10.8875L10.8607 10.8912C10.6284 11.1235 10.3133 11.254 9.98477 11.254C9.65624 11.254 9.34115 11.1235 9.1088 10.8912"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="vedio-play-info">
                      {item.sessions.map((c_item, c_index) => {
                        return (
                          <div
                            className="udr uac no-item"
                            key={c_index}
                            onClick={() => {
                              if (!courseInfo.flag) {
                                message.error("请先购买本课程");
                                return;
                              }
                              navigate(
                                `/course/play/${params.id}/${index}/${c_index}`
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              className="curp play-btn"
                              onClick={() => {
                                changePlay(c_index);
                              }}
                            >
                              <path
                                d="M2.2373 15C2.2373 16.676 2.56742 18.3356 3.2088 19.884C3.85019 21.4325 4.79028 22.8394 5.9754 24.0245C7.16052 25.2096 8.56747 26.1497 10.1159 26.7911C11.6643 27.4325 13.3239 27.7626 15 27.7626C16.676 27.7626 18.3356 27.4325 19.884 26.7911C21.4325 26.1497 22.8394 25.2096 24.0245 24.0245C25.2096 22.8394 26.1497 21.4325 26.7911 19.884C27.4325 18.3356 27.7626 16.676 27.7626 15C27.7626 11.6151 26.418 8.36886 24.0245 5.9754C21.6311 3.58194 18.3848 2.2373 15 2.2373C11.6151 2.2373 8.36886 3.58194 5.9754 5.9754C3.58194 8.36886 2.2373 11.6151 2.2373 15Z"
                                fill="#666767"
                              />
                              <path
                                d="M13.7818 9.1897C13.1389 8.60821 12.1111 9.06446 12.1111 9.93138V20.0245C12.1111 20.8914 13.1389 21.3477 13.7818 20.7662L19.362 15.7196C19.801 15.3226 19.801 14.6333 19.362 14.2363L13.7818 9.1897Z"
                                fill="white"
                              />
                            </svg>
                            <span className="no-name">{c_item.name}</span>
                            {c_item.isTry === "1" && (
                              <span
                                className="try-txt"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  tryPlay(c_item);
                                }}
                              >
                                试看
                              </span>
                            )}

                            {/* <span className="no-minute">--分钟</span> */}
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {/*<div className="comment-introduction">*/}
        {/*  <div className="de-trophy">*/}
        {/*    <img src={img12} />*/}
        {/*  </div>*/}
        {/*  <div className="udr uac ujc de-btn-gulp">*/}
        {/*    <button className="active">全部(684)</button>*/}
        {/*    <button>好评(684)</button>*/}
        {/*    <button>中评(153)</button>*/}
        {/*    <button>差评(0)</button>*/}
        {/*  </div>*/}
        {/*  <ul className="comment-list">*/}
        {/*    {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {*/}
        {/*      return (*/}
        {/*        <li className="udr" key={index}>*/}
        {/*          <img*/}
        {/*            className="userpic"*/}
        {/*            src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1796273328,1072574788&fm=253&gp=0.jpg"*/}
        {/*          />*/}
        {/*          <div className="udc userinfo">*/}
        {/*            <div className="udr uac ujb">*/}
        {/*              <span className="username">用户名称</span>*/}
        {/*              <span className="report">举报</span>*/}
        {/*            </div>*/}
        {/*            <div className="udr uac">*/}
        {/*              <span className="usertime">*/}
        {/*                5天前|已上课4天16小时19分时评论*/}
        {/*              </span>*/}
        {/*              <Rate value={3} />*/}
        {/*            </div>*/}
        {/*            <div className="comment">*/}
        {/*              这个课程实在是太棒啦！老师讲的很好哦，感觉自己又成长了哦！*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </li>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </div>

      <RightFloat />

      <PayWindow
        state={showWindow}
        businessId={params.id}
        query={detailData}
        money={(detailData.data?.course.price || 0).toFixed(2)}
        openFunction={() => {
          setShowWindow(false);
        }}
      />
    </div>
  );
};
export default Detail;
