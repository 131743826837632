import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import { Modal, message } from "antd";
import { getLocalUser } from "@/utils";

/** 加入班级 */
import request from "@/service/request";
import "./CreateClass.css";
import { useMutation } from "react-query";
const CreateClass = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const userInfo = useRef({});

  useEffect(() => {
    const user_info = getLocalUser();
    if (user_info?.userId) {
      userInfo.current = user_info;
    }
  }, []);

  const openHandel = () => {
    setOpen(true);
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));
  const closeHandel = () => {
    setOpen(false);
    setName("");
  };

  const addClass = useMutation(async () => {
    if (!name) {
      message.error("请输入课堂号码");
      return;
    }
    // 加入课堂
    const res = await request.post("/mmt/mineclasses/apply", {
      userId: userInfo.current.userId,
      classBh: name,
    });
    message.info("申请成功,等待老师审核！", 3);
    closeHandel();
    props.query.refetch();
    return res.data;
  });
  if (!open) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="mi-create-modal"
      closeIcon={null}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={388}
    >
      <div className="title">请输入课堂号</div>
      <div className="create-class-input">
        <div className="create-class-input-box">
          <input
            placeholder="请输入课堂号码"
            value={name}
            onInput={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="btn-gulp">
        <div className="te-btn confirm" onClick={addClass.mutate}>
          确认
        </div>
        <div className="te-btn cancel" onClick={closeHandel}>
          取消
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(CreateClass);
