import React, { useRef, useState } from "react";
import user from "@ass/base/user.png";

import { useNavigate } from "react-router-dom";
import styles from "./addCourse.module.css";
import bg from "@ass/classPage/classimg.png";
import empty from "@ass/course/empty.png";
import { useMutation, useQuery } from "react-query";
import request from "@/service/request";
import { Empty, message } from "antd";
import { useClassContext } from "@/pages/teacher/myClass/myClass";
const courseManage = () => {
  const navigate = useNavigate();
  const { id } = useClassContext();
  const nameVal = useRef(null);

  // 课程筛选
  const searchCourse = useMutation(async () => {
    const res = await request.post("/mmt/classescourse/classesCourse", {
      pageNum: 1,
      pageSize: 15,
      name: nameVal.current,
      classesId: id,
      buyStatus: 1,
    });
    return res.data;
  });

  // 班级添加课程
  const classJoinCourse = useMutation(async ({ courseId, price }) => {
    const res = await request.post("/mmt/classescourse/add", {
      classesId: id,
      courseId: courseId,
      free: price > 0 ? "1" : "0",
    });
    currentClassCourse.refetch();
    message.success("操作成功！");
    if (res.code === 200) {
      searchCourse.mutate();
    }
    return res.data;
  });

  // 查询班级课程
  const currentClassCourse = useQuery(
    ["/mmt/classescourse/list/" + id],
    async () => {
      const res = await request.get("/mmt/classescourse/list", {
        pageNum: 1,
        pageSize: 50,
        classesId: id,
      });
      return res.rows;
    },
    {
      cacheTime: 0,
      enabled: Boolean(id),
    }
  );

  const deleteCourse = useMutation(async (id) => {
    const res = await request.post(`/mmt/classescourse/delete/${id}`);
    message.success("操作成功！");
    currentClassCourse.refetch();
    return res.data;
  });

  return (
    <div className={"tea-container " + styles.addCoursePage}>
      <div className="udr uac curp goback">
        <div
          className="udr uac curp"
          onClick={() => {
            navigate(-1);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M17.3906 20.4453L10.6887 13.7434L17.3906 7.04141"
              stroke="#666767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>返回班级</span>
        </div>
      </div>
      <div className={"udr uf1 " + styles.addCourseCon}>
        <div className="udc uf1 system-sub">
          <div className={"udr uac " + styles.searchCon}>
            <div className={styles.title}>添加课程</div>
            <div className={"udr uac search-input " + styles.inputCon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.0982 24.0611L19.2393 19.2021"
                  stroke="#00648E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                placeholder="请搜索需要添加的课程"
                onInput={(e) => {
                  nameVal.current = e.target.value;
                  searchCourse.mutate();
                }}
              />
            </div>
          </div>
          {searchCourse.data?.length ? (
            <ul className={styles.courseList}>
              {searchCourse.data.map((item, index) => {
                return (
                  <li key={index}>
                    <img
                      className={styles.courseBg}
                      src={item.coverPath ? item.coverPath : bg}
                    />
                    <div className={styles.courseInfo}>
                      <span className="line-hide-2">{item.name}</span>
                      <span>{item.teachName}</span>
                    </div>
                    <div className={styles.price}>
                      {item.price > 0 ? `￥${item.price}` : "免费"}
                    </div>
                    {item.buyStatus === "1" ? (
                      <div className={"curp " + styles.joinSucc}>已添加</div>
                    ) : (
                      <div
                        className={"curp " + styles.join}
                        onClick={() => {
                          classJoinCourse.mutate({
                            courseId: item.id,
                            price: item.price,
                          });
                        }}
                      >
                        添加
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className={"uf1  " + styles.empty}>
              <div className={"udr uac ujc " + styles.emptyImg}>
                <img src={empty} />
              </div>
              <div className={"'udr uac ujc " + styles.emptyTxt}>
                <span>没有任何课程，请先去</span>
                <span className={styles.tip}>搜索课程哦</span>
              </div>
            </div>
          )}
        </div>
        <div className="uf1 udc system-main">
          <div className={"apply-box te-course-list " + styles.addCourseList}>
            <div className="udr uac apply-title">
              <div className={styles.title}>班级课程</div>
              <div className={"udr uac search-input " + styles.inputCon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M12.9281 21.8269C17.8633 21.8269 21.8641 17.8262 21.8641 12.891C21.8641 7.95583 17.8633 3.95508 12.9281 3.95508C7.99294 3.95508 3.99219 7.95583 3.99219 12.891C3.99219 17.8262 7.99294 21.8269 12.9281 21.8269Z"
                    stroke="#00648E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.0982 24.0611L19.2393 19.2021"
                    stroke="#00648E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input placeholder="请输入课程名称" />
              </div>
            </div>
            {currentClassCourse.data?.length ? (
              <ul className="apply-ul">
                {currentClassCourse.data.map((item, index) => {
                  return (
                    <li className="udr uac ujb" key={index}>
                      <img
                        src={
                          item.mmtCourse.coverPath
                            ? item.mmtCourse.coverPath
                            : bg
                        }
                        className={styles.courseBg}
                      />
                      <div className={styles.courseInfo}>
                        <span className="line-hide-2">
                          {item.mmtCourse.name}
                        </span>
                        <span>{item.mmtCourse.teachName}</span>
                      </div>
                      <span
                        className={styles.cacelCourseBtn}
                        onClick={() => {
                          deleteCourse.mutate(item.id);
                        }}
                      >
                        取消
                      </span>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className={styles.emptyBox}>
                <Empty />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default courseManage;
