import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import my_left from "@ass/mine/bg1.png";
import my_right from "@ass/mine/bg2.png";
import user from "@ass/base/user.png";
import nav3 from "@ass/mine/nav3.png";
import nav4 from "@ass/mine/nav4.png";
import nav_bg from "@ass/mine/nav-bg.png";
import logo from "@ass/logo.png";
import { getLocalUser } from "@/utils";

import "./teacher.css";
import { useNavigate, useLocation } from "react-router-dom";

const navList = [
  {
    txt: "我的班级",
    icon: nav3,
    pathname: "/teacher/index/courseManage",
  },
  // {
  //   txt: "比赛管理",
  //   icon: nav4,
  //   pathname: "/teacher/match/index",
  // },
  {
    txt: "设置",
    icon: nav4,
    pathname: "/teacher/teacherSetting",
  },
];

const Teacher = () => {
  const [navIndex, setNavIndex] = useState(0);
  const navigate = useNavigate();
  const localtion = useLocation();

  const [userinfo, setUserinfo] = useState({});

  useEffect(() => {
    const userInfo = getLocalUser();
    setUserinfo(userInfo);

    navList.forEach((item, index) => {
      if (item.pathname === localtion.pathname) {
        setNavIndex(index);
      }
    });
  }, []);
  const routeChange = (item, index) => {
    if (item.pathname) {
      console.log(item.pathname);
      navigate(item.pathname);
    }
    setNavIndex(index);
  };

  return (
    <div className="page-container teacher-container">
      <div className="my-info">
        <div
          className="udr uac ujb left-box"
          style={{ backgroundImage: `url(${my_left})` }}
        >
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            className="logo"
          />
          <img src={user} className="my-user-pic" />
        </div>
        <div
          className="udr uac right-box"
          style={{ backgroundImage: `url(${my_right})` }}
        >
          <div className="uf1 udc username ">
            <div className="udr uac">
              <span className="nikename">{userinfo.nickName}</span>
              <span className="career">老师</span>
            </div>
            <div>这位老师很懒，什么也没留下~</div>
          </div>
          <div className="uf1 udc user-integral">
            <span className="user-integral-num">{userinfo.integral}</span>
            <span className="user-integral-txt">积分</span>
          </div>
          {/*<div className="udr uf1 uac uje">*/}
          {/*  <div className="udr uac ujc my-setting">*/}
          {/*    <svg*/}
          {/*      xmlns="http://www.w3.org/2000/svg"*/}
          {/*      width="19"*/}
          {/*      height="20"*/}
          {/*      viewBox="0 0 19 20"*/}
          {/*    >*/}
          {/*      <path*/}
          {/*        fillRule="evenodd"*/}
          {/*        clipRule="evenodd"*/}
          {/*        d="M6.42857 2C6.42857 0.895431 7.324 0 8.42857 0H10.4286C11.5331 0 12.4286 0.895431 12.4286 2V4.80389L14.8567 3.40199C15.8133 2.84971 17.0365 3.17746 17.5888 4.13404L18.5888 5.86609C19.1411 6.82268 18.8133 8.04586 17.8567 8.59814L15.4286 10L17.8568 11.402C18.8134 11.9542 19.1411 13.1774 18.5888 14.134L17.5888 15.8661C17.0365 16.8227 15.8134 17.1504 14.8568 16.5981L12.4286 15.1962V18C12.4286 19.1046 11.5331 20 10.4286 20H8.42857C7.324 20 6.42857 19.1046 6.42857 18V15.1962L4.00033 16.5981C3.04375 17.1504 1.82057 16.8227 1.26828 15.8661L0.268283 14.134C-0.284002 13.1775 0.0437487 11.9543 1.00033 11.402L3.42858 10L1.00038 8.59812C0.0437909 8.04583 -0.28396 6.82265 0.268325 5.86607L1.26832 4.13402C1.82061 3.17743 3.04379 2.84968 4.00038 3.40196L6.42857 4.80389V2ZM9.42859 13C11.0854 13 12.4286 11.6569 12.4286 10C12.4286 8.34315 11.0854 7 9.42859 7C7.77173 7 6.42859 8.34315 6.42859 10C6.42859 11.6569 7.77173 13 9.42859 13Z"*/}
          {/*        fill="#FFF5F5"*/}
          {/*      />*/}
          {/*    </svg>*/}
          {/*    <span>个人设置</span>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="udr mine-main">
        <ul className="my-nav-list">
          {navList.map((item, index) => {
            return (
              <li
                key={index}
                className={navIndex === index ? "active" : ""}
                onClick={() => {
                  routeChange(item, index);
                }}
              >
                <div className="mine-nav-icon">
                  <img src={item.icon} />
                </div>
                <span className="mine-nav-txt">{item.txt}</span>
                <img src={nav_bg} />
              </li>
            );
          })}
        </ul>
        <div className="uf1 udr three-route">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Teacher;
