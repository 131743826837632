import React from "react";
import './state.css'
import { Head } from "@components";
import search from "@ass/information/search.png";
// import search from "@ass/in"
import { Space, Table, Tag } from 'antd';

const { Column, ColumnGroup } = Table;

const data = [
    {
        key: '1',
        firstName: '文件名称',
        lastName: 'Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        firstName: '所有者',
        lastName: 'Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        firstName: '提交时间',
        lastName: 'Black',
        age: 32,
        address: 'Sydney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];
const State =()=>{
    return(
        <div className='State'>
            <div className = "State-box" >
                <div className='state-put'>
                    <img src={search} alt="" />
                    <input placeholder='请输入用户名称' type="text" className='put'  />
                </div>
                <Table  className='state-table' dataSource={data}>
                    <Column title="用户名称" dataIndex="firstName" key="firstName" width={400} />
                    <Column title="题目" dataIndex="lastName" key="lastName" width={500} />
                    <Column title="分数" dataIndex="age" key="age" width={200} />
                    <Column title="分数" dataIndex="age" key="age" width={200} />
                    <Column title="耗时" dataIndex="age" key="age" width={200} />
                    <Column title="内存" dataIndex="age" key="age" width={200} />
                    <Column title="类别" dataIndex="age" key="age" width={200} />
                    <Column title="提交时间" dataIndex="age" key="age" width={400} />
                </Table>
            </div>
        </div>
    )
}


export default State;