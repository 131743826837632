import React, { useState } from "react";
import "./user.css";
import { Table } from "antd";

const { Column } = Table;

const data = [
  {
    key: "1",
    firstName: "文件名称",
    lastName: "Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    firstName: "所有者",
    lastName: "Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    firstName: "提交时间",
    lastName: "Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
const columns = [
  {
    title: "名次",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "用户名称",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "个人简介",
    dataIndex: "age",
    key: "age",
  },
];
const User = () => {
  const [top, setTop] = useState("none");
  const [bottom, setBottom] = useState("bottomRight");
  return (
    <div className="user-box">
      <div className="user-content">
        <Table
          dataSource={data}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            // total: 50,
            hideOnSinglePage: true,
            position: [top, bottom],
          }}
          title={() => {
            return <span className="user-title">用户排名</span>;
          }}
        />
      </div>
    </div>
  );
};

export default User;
