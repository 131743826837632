import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper/js/swiper.js";
import "swiper/css/swiper.min.css";

import left from "@ass/index/left.png";
import right from "@ass/index/right.png";
import girl from "@ass/index/girl.png";
import li33 from "@ass/index/33.png";
import li46 from "@ass/index/46.png";
import add from "@ass/index/add.png";
import search from "@ass/index/search.png";
import search38 from "@ass/index/search38.png";
import teacher from "@ass/index/teacher.png";
import huojian from "@ass/index/huojian.png";
import { getToken } from "@/utils";
import { useNavigate } from "react-router-dom";
import { OJURL } from "@/service/config";

import teacherPic from "@ass/index/teacher-1.png";
import teacher1 from "@ass/index/teacher1.png";
import teacher2 from "@ass/index/teacher2.png";
import teacher3 from "@ass/index/teacher3.png";
import teacher4 from "@ass/index/teacher4.png";
import teacher5 from "@ass/index/teacher5.png";
import tipImg from "@ass/index/tip.png";
import { Modal } from "antd";

import { RightFloat, Logo, LoginBtn } from "@components";

import "./index.css";

import request from "@/service/request";
import { useQuery } from "react-query";

const Index = () => {
  const navigate = useNavigate();
  const loginBtnRef = useRef(null);
  const [tabsList] = useState([
    {
      key: "1",
      label: "首页",
    },
    {
      key: "2",
      label: "精品课堂",
      routename: "/course",
    },
    {
      key: "3",
      label: "精品题库",
      routename: "/oj/questionBank",
    },
    {
      key: "4",
      label: "相关资料",
      routename: "/informationIndex",
    },
  ]);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    title: "",
    content: "",
  });

  const [tabsActiveKey, setTabsActiveKey] = useState("1");

  const [teacherIndex, setTeacherIndex] = useState(0);

  const query = useQuery(
    ["/mmt/front/page-image", "/mmt/oj/game-list"],
    async () => {
      const [res, notice, teacher, compet] = await Promise.all([
        request.get("/mmt/front/page-image", {
          type: "首页图片",
        }),
        request.get("/mmt/front/page-image", {
          type: "通知图片",
        }),
        request.get("/mmt/front/page-image", {
          type: "老师图片",
        }),
        request.get("/mmt/oj/game-list"), // 比赛通知
      ]);
      return {
        bannerList: res.rows,
        noticeList: notice.rows,
        teacherList: teacher.rows,
        compet: compet.rows,
      };
    }
  );

  useEffect(() => {
    // 配置swiper播放 配置项都写这里面
    new Swiper(".notice-swiper-container", {
      direction: "horizontal", // 轮播图轮播方向，horizontal为横向，
      loop: true, //循环
      autoplay: true,
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".notice-right",
        prevEl: ".notice-left",
      },
      spaceBetween: 24, //左右间距
      slidesPerView: 3, //这必须要加，否则返回第一页的时候，右边模块会缺失
      centeredSlides: true, // 每屏，居中显示
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
    });

    new Swiper(".banner-swiper-container", {
      direction: "horizontal", // 轮播图轮播方向，horizontal为横向，
      loop: true, //循环
      autoplay: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      spaceBetween: 0,
      slidesPerView: "auto",
      centeredSlides: true, // 每屏，居中显示
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, // 修改swiper的父元素时，自动初始化swiper
    });
  }, []);

  const callback = (key, index) => {
    let token = getToken();
    if (!token) {
      loginBtnRef.current.open();
      return;
    }
    const routename = tabsList[index].routename;
    if (key === "3") {
      window.open(OJURL + `/problem?token=${getToken()}`);
    } else if (routename) {
      setTabsActiveKey(key);
      navigate(routename);
    }
  };

  const showModal = (data) => {
    setModalInfo({
      ...data,
      open: true,
    });
  };

  const handleOk = () => {
    setModalInfo({
      ...modalInfo,
      open: false,
    });
  };

  const handleCancel = () => {
    handleOk();
  };

  return (
    <div className="page" style={{ marginBottom: -64 }}>
      <div className="page-container in-container">
        <div className="head">
          <Logo size={100} />
          <div className="tab-box">
            {tabsList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`tab-list-item ${
                    item.key === tabsActiveKey && "tab-list-active"
                  }`}
                  onClick={() => {
                    callback(item.key, index);
                  }}
                >
                  {item.label}
                </div>
              );
            })}
            <LoginBtn ref={loginBtnRef} />
          </div>
        </div>
        <div className="banner-carousel">
          <div className="banner-swiper-container swiper-container">
            <div className="swiper-wrapper">
              {query.data?.bannerList.map((item, index) => {
                return (
                  <div className="swiper-slide" key={index}>
                    <a target="_blank" href={item.linkUrl}>
                    <img className="banner" src={item.url} alt="banner" />
                    </a>
                  </div>
                );
              })}
              {/* <div className="swiper-slide">
                <img className="banner" src={banner2} alt="logo" />
              </div>
              <div className="swiper-slide">
                <img className="banner" src={banner3} alt="logo" />
              </div>
              <div className="swiper-slide">
                <img className="banner" src={banner4} alt="logo" />
              </div> */}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>

        <div className="udr uac ujc notice">
          <div className="notice-title">
            <span>相关通知</span>
            <span>Related notices</span>
          </div>
        </div>
        <div className="udr uac notice-carousel">
          <div className="notice-left">
            <img src={left} />
          </div>
          <div className="notice-swiper-container">
            <div className="swiper-wrapper">
              {query.data?.noticeList.map((item, index) => {
                return (
                  <div
                    className="curp swiper-slide"
                    key={index}
                    onClick={() => {
                      showModal(item);
                    }}
                  >
                    <img className="notice-img" src={item.url} alt="notice" />
                    <div className="notice-txt-box">
                      <span className="notice-txt-1">{item.title}</span>
                      {/* <span className="notice-txt-2">{item.content}</span> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="notice-right">
            <img src={right} />
          </div>
        </div>

        {/* <div className="udr uac ujc active-box">
          <img className="girl" src={girl} />
          <div className="active-left">
            <span className="title">易源信奥比赛通知</span>
            <ul className="index-ul">
              {query.data?.compet.map((item, index) => {
                return (
                  <li
                    className="item curp"
                    key={index}
                    onClick={() => {
                      window.open(
                        OJURL + `/contest/${item.id}?token=${getToken()}`
                      );
                    }}
                  >
                    <img className="trophy" src={tipImg} alt="notice" />
                    <div className="competition">
                      <div className="competition-box">
                        <span className="name">{item.title}</span>
                        <span className="line" />
                      </div>
                      <div>{item.content}</div>
                      <div className="udr uac ujb detail">
                        <div>
                          <span className="label">时间</span>
                          <span className="value">{item.time}</span>
                        </div>
                        <div>
                          <span className="label">学科</span>
                          <span className="value color-green">
                            {item.language}
                          </span>
                        </div>
                      </div>
                    </div>
                    <img className="label-img" src={li46} alt="notice" />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="active-right ">
            <div
              className="more curp"
              onClick={() => {
                window.open(OJURL + `/contest?token=${getToken()}`);
              }}
            >
              <span>更多</span>
              <img className="add" src={add} />
            </div>
            <div className="search">
              <div className="udr ujc uac search-input">
                <input placeholder="比赛一点通" disabled />
                <img className="search-icon" src={search} />
              </div>
            </div>
            <img className="search-pepo" src={search38} />
          </div>
        </div> */}

        <div className="udr uac ujc notice">
          <div className="notice-title">
            <span>特色师资</span>
            <span>Featured Teacher</span>
          </div>
        </div>
        <div className="uac ujc teacher">
          <div className="introduce">
            <div className="name">
              {query.data?.teacherList[teacherIndex].remark}
            </div>
            <div className="course">
              <span className="det-main">
                学历:
                <span className="det-name">
                  {query.data?.teacherList[teacherIndex].title}
                </span>
              </span>
              <span className="line" />
            </div>
            <div className="sub">
              {query.data?.teacherList[teacherIndex].content}
            </div>
          </div>
          <img
            className="teacher-img"
            src={query.data?.teacherList[teacherIndex].url}
          />
          <div className="udr uac ujc mention">
            <span>硬 核 师 资 团</span>
            <img className="huojian-img" src={huojian} />
          </div>
        </div>
        <div className="udr teacher-list">
          {query.data?.teacherList.map((item, index) => {
            return (
              <div
                className={
                  index === teacherIndex
                    ? "teacher-active teacher-item"
                    : "teacher-item"
                }
                onClick={() => {
                  setTeacherIndex(index);
                }}
                key={index}
              >
                <img src={item.url} />
                <span>{item.remark}</span>
              </div>
            );
          })}
        </div>
        <div className="ellipse " />
        <RightFloat />
        <Modal
          title={modalInfo.title}
          open={modalInfo.open}
          centered
          onOk={handleOk}
          footer={null}
          onCancel={handleCancel}
        >
          <p>{modalInfo.content}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Index;
