import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Modal, message } from "antd";
import { copyToClipboard } from "@/utils";

const AddStudent = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [classBH, setClassBH] = useState(null);

  const openHandel = (id) => {
    setOpen(true);
    setClassBH(id);
  };
  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));
  const closeHandel = () => {
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={null}
      footer={null}
      centered
      className="te-create-modal"
      closeIcon={null}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={closeHandel}
      width={388}
    >
      <div className="title tac">班级号码</div>
      <div className="create-class-input">
        <div className="create-class-input-box">
          <input value={classBH} disabled />
          <span onClick={()=>{
            message.success('复制成功')
            copyToClipboard(classBH)
          }}>复制</span>
        </div>
      </div>
      <div className="btn-gulp">
        <div className="te-btn confirm" onClick={closeHandel}>
          确认
        </div>
        <div className="te-btn cancel" onClick={closeHandel}>
          取消
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(AddStudent);
