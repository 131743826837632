import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import LoginModal from "@/pages/index/login";
import userpng from "@ass/base/user.png";
import mycenter from "@ass/base/2466.png";
import { Popover, message,Modal,Input,Button } from "antd";
import { useNavigate } from "react-router-dom";
import { DesktopOutlined,AccountBookOutlined } from "@ant-design/icons";
import {OJ_ADMIN_URL, ADMINURL, OJURL} from "@/service/config";
import request from "@/service/request";
import { getToken } from "@/utils";

import { STUDENT, TEACHER, INSTITUTION } from "@/config";

import { getLocalUser, removeToken, removeUser } from "@/utils";

const LoginBtn = (props, ref) => {
  const loginModalRef = useRef(null);
  const navigate = useNavigate();
  const [codeState,setCodeState] = useState(false)
  const [code,setCode] = useState("")
  // 兑换兑换码
  const changeCode = async()=>{
    try {
      const {data} = await request.post("/mmt/code/exchange", {
        code
      });
      if(data){
        message.success("兑换成功")
        closeCodeWindow()
        if(data.codeType && data.codeType === 2){
          setTimeout(() => {
            window.open(OJURL + `/problem?token=${getToken()}`);
          }, 1500);
        }else{
        navigate(`/course/detail/${data.businessId}`)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const closeCodeWindow =()=>{
    setCode("");
    setCodeState(false)
  }
  const [userInfo, setUserInfo] = useState({});
  const logout = () => {
    removeToken();
    removeUser();
    setUserInfo({});
  };
  const openHandel = () => {
    loginModalRef.current.open();
  };

  useImperativeHandle(ref, () => ({
    open: openHandel,
  }));

  useEffect(() => {
    const user_info = getLocalUser();
    if (user_info?.userId) {
      setUserInfo(user_info);
    }
  }, []);

  // 跳转到个人中心
  const jumpTo = () => {
    if (userInfo?.roleName) {
      switch (userInfo.roleName) {
        case STUDENT:
          navigate("/mine/index");
          break;
        case TEACHER:
          navigate("/teacher/index/courseManage");
          break;
        case INSTITUTION:
          navigate("/agentIndex/school");
          break;
      }
    } else {
      message.error("无法查询用户信息，请重新登录");
    }
  };

  const content = (
    <div className="dropdown">
      <div className="username">{userInfo.nickName}</div>
      <div className="udr uac ujc">
        <div>
          <span className="lab">ID:</span>
          <span className="val">{userInfo?.userId}</span>
        </div>
        <div className="integ">
          <span className="lab">积分:</span>
          <span className="val">{userInfo.integral}</span>
        </div>
      </div>
      <div
        className="udr ujb uac my-center"
        style={{ backgroundImage: `url(${mycenter})` }}
      >
        <div className="udc ">
          <span className="my-integral-x">我的积分</span>
          <span className="my-integral-t">积分兑换好礼</span>
        </div>
      
        <div className="my-center-btn curp" onClick={jumpTo}>
          个人中心
        </div>
      </div>
      <div
        className="udr uac ujb dropdown-item dropdown-bb curp"
        onClick={() => {
          setCodeState(true)
        }}
      >
        <AccountBookOutlined />
        <span className="uf1 course-t">兑换课程</span>
        <RightIcon />
      </div>
      {userInfo.userName === "root" && (
        <>
          <div className="udr uac ujb dropdown-item dropdown-bb curp" onClick={()=>{
            // console.log(ADMINURL + `?token=${getToken()}`);
            window.open(ADMINURL + `?token=${getToken()}`);
          }}>
            <DesktopOutlined />
            <span className="uf1 course-t">萌萌码后台</span>
            <RightIcon />
          </div>
          <div
            className="udr uac ujb dropdown-item dropdown-bb curp"
            onClick={() => {
              window.open(OJ_ADMIN_URL + `?token=${getToken()}`);
            }}
          >
            <DesktopOutlined />
            <span className="uf1 course-t">OJ后台</span>
            <RightIcon />
          </div>
        </>
      )}

      <div
        className="udr uac ujb dropdown-item course-out curp"
        onClick={logout}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <mask id="path-1-inside-1_1453_506" fill="white">
            <path d="M11.1978 4.06079C10.5718 3.16679 9.67672 2.49599 8.64295 2.14611C7.60917 1.79623 6.49069 1.78554 5.45042 2.1156C4.41015 2.44566 3.50242 3.09923 2.85946 3.98111C2.21651 4.86299 1.8719 5.92711 1.87583 7.01848C1.87977 8.10986 2.23205 9.17147 2.88135 10.0487C3.53066 10.9259 4.44307 11.5729 5.4857 11.8955C6.52833 12.218 7.6467 12.1992 8.67792 11.8419C9.70915 11.4846 10.5994 10.8073 11.2189 9.90885L10.2571 9.24569C9.77881 9.93935 9.09155 10.4622 8.29543 10.7381C7.4993 11.0139 6.63589 11.0284 5.83096 10.7794C5.02604 10.5304 4.32163 10.0309 3.82036 9.35364C3.31908 8.67642 3.04712 7.85683 3.04408 7.01427C3.04104 6.17171 3.30708 5.35019 3.80346 4.66936C4.29984 3.98853 5.00062 3.48396 5.80373 3.22915C6.60684 2.97433 7.47033 2.98258 8.26843 3.2527C9.06652 3.52281 9.75753 4.04068 10.2408 4.73087L11.1978 4.06079Z" />
          </mask>
          <path
            d="M11.1978 4.06079C10.5718 3.16679 9.67672 2.49599 8.64295 2.14611C7.60917 1.79623 6.49069 1.78554 5.45042 2.1156C4.41015 2.44566 3.50242 3.09923 2.85946 3.98111C2.21651 4.86299 1.8719 5.92711 1.87583 7.01848C1.87977 8.10986 2.23205 9.17147 2.88135 10.0487C3.53066 10.9259 4.44307 11.5729 5.4857 11.8955C6.52833 12.218 7.6467 12.1992 8.67792 11.8419C9.70915 11.4846 10.5994 10.8073 11.2189 9.90885L10.2571 9.24569C9.77881 9.93935 9.09155 10.4622 8.29543 10.7381C7.4993 11.0139 6.63589 11.0284 5.83096 10.7794C5.02604 10.5304 4.32163 10.0309 3.82036 9.35364C3.31908 8.67642 3.04712 7.85683 3.04408 7.01427C3.04104 6.17171 3.30708 5.35019 3.80346 4.66936C4.29984 3.98853 5.00062 3.48396 5.80373 3.22915C6.60684 2.97433 7.47033 2.98258 8.26843 3.2527C9.06652 3.52281 9.75753 4.04068 10.2408 4.73087L11.1978 4.06079Z"
            stroke="#666666"
            strokeWidth="2"
            strokeLinejoin="round"
            mask="url(#path-1-inside-1_1453_506)"
          />
          <path
            d="M14 7H7"
            stroke="#666666"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
        <span className="uf1 course-t ">退出登录</span>
      </div>
    </div>
  );

  return (
    <>
      {userInfo?.userId ? (
        <div className="udr uac userinfo">
          <Popover content={content}>
            <img src={userpng} />
          </Popover>
        </div>
      ) : (
        <button className="login-btn" onClick={openHandel}>
          请登录
        </button>
      )}
      <LoginModal ref={loginModalRef} setUserInfo={setUserInfo} />
      <Modal
      width={688}
      wrapClassName="Modal"
      centered={true}
      footer={null}
      open={codeState}
      destroyOnClose={true}
      onCancel={closeCodeWindow}
    >
      <div style={{marginBottom:'20px'}}>
        <div style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <div >兑换码:</div>
          <Input  value={code} 
            onPressEnter={changeCode}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          maxLength="20" style={{width:'300px'}} placeholder="请输入兑换码" />
          <Button type="primary" htmlType="submit" onClick={changeCode}>
            确认兑换
          </Button>
        </div>
      </div>
      <div>
      </div>
    </Modal>
    </>
  );
};
const RightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
    >
      <path
        d="M0.841266 7.86088C0.748981 7.95116 0.62538 8.00111 0.497084 7.99998C0.368789 7.99885 0.246063 7.94673 0.155341 7.85484C0.0646191 7.76295 0.0131587 7.63865 0.0120439 7.50871C0.010929 7.37876 0.0602489 7.25357 0.149381 7.1601L3.26335 4.00609L0.149381 0.852082C0.102647 0.806365 0.0653696 0.751677 0.0397254 0.691212C0.0140811 0.630746 0.000583054 0.565713 1.8475e-05 0.499908C-0.000546104 0.434102 0.0118343 0.368841 0.0364373 0.307934C0.0610402 0.247026 0.0973728 0.191692 0.143316 0.145159C0.189258 0.0986252 0.243891 0.0618248 0.304025 0.0369058C0.36416 0.0119863 0.428592 -0.000552177 0.493562 1.90735e-05C0.558533 0.000590801 0.62274 0.0142622 0.682438 0.0402365C0.742136 0.0662103 0.796128 0.103966 0.841266 0.151301L4.29971 3.65372L4.30118 3.6557C4.39291 3.74864 4.44444 3.87467 4.44444 4.00609C4.44444 4.13751 4.39291 4.26354 4.30118 4.35648"
        fill="#666666"
      />
    </svg>
  );
};

const LeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41177 0.970588H2.58824C1.69483 0.970588 0.970588 1.69483 0.970588 2.58823V9.05882C0.970588 9.95222 1.69483 10.6765 2.58824 10.6765H8.41177C9.30517 10.6765 10.0294 9.95222 10.0294 9.05882V2.58824C10.0294 1.69483 9.30517 0.970588 8.41177 0.970588ZM2.58824 0C1.15879 0 0 1.15879 0 2.58823V9.05882C0 10.4883 1.15879 11.6471 2.58824 11.6471H8.41177C9.84121 11.6471 11 10.4883 11 9.05882V2.58824C11 1.15879 9.84121 0 8.41177 0H2.58824Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26465 0.32373H6.147V4.37528C6.147 4.83305 5.68466 5.14607 5.25963 4.97606L4.20582 4.55453L3.15202 4.97606C2.72699 5.14607 2.26465 4.83305 2.26465 4.37528V0.32373ZM2.91171 0.970789V4.37528L3.96551 3.95376C4.11978 3.89205 4.29187 3.89205 4.44614 3.95376L5.49994 4.37528V0.970789H2.91171Z"
        fill="#666666"
      />
    </svg>
  );
};

export default forwardRef(LoginBtn);
