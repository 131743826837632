import React from "react";
import { getLocalUser } from "@/utils";

function withAuthorization({ Component, allowedRoles }) {
  let userRoles = "";
  const userinfo = getLocalUser(); // 获取当前用户的角色信息

  if (userinfo?.roleName) {
    userRoles = userinfo.roleName;
  }

  if (!userRoles || !allowedRoles.includes(userRoles)) {
    return <h1>无权访问</h1>; // 角色不匹配，则重定向到未授权页面
  }

  return <Component />;
}
export default withAuthorization;
